import FeatureModuleEnum from '@/enums/FeatureModuleEnum';
import { LinkedFeatureViewModel } from '@/entities/LinkedFeatureViewModel';

export class LinkedFeatureTabList {
  public LinkedFeatureTabList: LinkedFeatureViewModel[] = [];
  constructor() {
    this.LinkedFeatureTabList.push({ MainId: FeatureModuleEnum.Program, LinkedId: [FeatureModuleEnum.MyProgram] });
    this.LinkedFeatureTabList.push({ MainId: FeatureModuleEnum.Participants, LinkedId: [FeatureModuleEnum.ActivityFeed, FeatureModuleEnum.Chat, FeatureModuleEnum.Gamification] });
  }
}
