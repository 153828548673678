import { ApplicationInstanceModel } from '@/entities/EventSetting/ApplicationInstanceModel';
import CustomMenuDefaultTranslationsViewModel from '@/entities/EventSetting/CustomMenuDefaultTranslationsViewModel';
import LanguagesViewModel from '@/entities/EventSetting/LanguagesViewModel';
export default class CustomMenuDTO {
  public static createEmpty(): CustomMenuDTO {
    return new CustomMenuDTO();
  }
  constructor(
    public languages: LanguagesViewModel[] = [],
    public customMenuDefaultTranslations: CustomMenuDefaultTranslationsViewModel[] = [],
    public eventDetails: ApplicationInstanceModel = ApplicationInstanceModel.createEmpty(),
  ) {}
}
