
import { Vue, Component, Prop, Watch, toNative } from 'vue-facing-decorator';
import { EventSettingInfo } from '@/entities/EventSetting/EventSettingInfo';
// import { required, minLength } from 'vuelidate/lib/validators';
import { Twitter } from '@ckpack/vue-color';
import { DialogueThemeEntity } from '@/entities/DialogueTheme/DialogueThemeEntity';
import { StoreHelper } from '@/store/StoreHelper';
import { AppColorSettingsInfo } from '@/entities/Application/AppColorSettingsInfo';
import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';

import Highcharts from 'highcharts';
import DialogueThemesSettings from '@/helper/DialogueThemesSettings';

import Charts from '../DialogueCharts/DialogueCharts.vue';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import ImageProcess from '@/components/Common/ImageProcess.vue';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import loading from '@/components/Common/loading.vue';
import Toaster from '@/components/Common/Toaster.vue';
import vClickOutside from 'click-outside-vue3';
import SubModulePrefix from '@/enums/SubModulePrefix';
import { app, FetchData, Root, Store, t } from '@/main';
import { reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';

@Component({
  components: {
    Charts,
    VueModalPopUp,
    ImageProcess,
    ValidationSummary,
    loading,
    Toaster,
    Twitter,
    vClickOutside,
  },
})
class CreateCustomTheme extends Vue {
  // Properties
  @Prop() customThemeData!: DialogueThemeEntity;
  // Local variables
  private localChartEntity: DialogueThemeEntity = DialogueThemeEntity.createEmpty();
  private oldLocalChartEntity: DialogueThemeEntity = DialogueThemeEntity.createEmpty();
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private navColor = '';
  private fontColor = {hex: DialogueThemesSettings.FontColor};
  private backgroundColor = { hex: '' };
  private palette1Color = { hex: '' };
  private palette2Color = { hex: '' };
  private palette3Color = { hex: '' };
  private palette4Color = { hex: '' };
  private palette5Color = { hex: '' };
  private palette6Color = { hex: '' };
  private isPanelDirty = false;
  private isPaletteSelected = [true,true,true,true,true,true,true,true];
  private addIcon = require('@/assets/Images/add-icon-grey.svg');
  private showVueModalPopUp = false;
  private unsavedModalPopUp: VueModalPopUpViewModel = VueModalPopUpViewModel.createEmpty();
  private showImageProcess = false;
  private moduleName = 'CustomTheme';
  private showFeaturedImage = false;
  public missingImage: string = require('@/assets/Images/ImageMissingFeature.png');
  public featureMissingImage: string = require('@/assets/Images/icon-placeholder.svg');
  private FeaturedImageHeight = DialogueThemesSettings.DefaultBackgroundImageHeight;
  private FeaturedImageWidth = DialogueThemesSettings.DefaultBackgroundImageWidth;
  private localEventDetailViewModal: EventSettingInfo = EventSettingInfo.createEmpty();
  private toasterMessage = t('EventSetting.CustomTheme.SucessCreateMessage');
  private toasterDefaultTimeout = 3000;
  private monoColor = '';
  private showToaster = false;
  private toaterTimeOut = 1000;
  private includeLogoError = false;
  private successMessage = false;
  private disablePage = false;
  private subModuleName = '';
  // variables for validation
  private state = reactive({
    localChartEntity: DialogueThemeEntity.createEmpty(),
  });

  private rules = {
    localChartEntity: {
      Name: { required, minLength: minLength(1) },
    } ,
  };

  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);
  private created(){
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    app.use(vClickOutside);
  }
  // Call on the component load
  private mounted() {
    this.localChartEntity = JSON.parse(JSON.stringify(this.customThemeData));
    this.oldLocalChartEntity = JSON.parse(JSON.stringify(this.customThemeData));
    this.state.localChartEntity = this.localChartEntity;
    if(this.localChartEntity.IsMultiColorTheme) {
      this.oldLocalChartEntity.ChartColors = this.localChartEntity.ChartColors = this.localChartEntity.ThemeColors.map((colour) => colour.Color);
    } else {
      if(this.localChartEntity !== null && this.localChartEntity !== undefined && this.localChartEntity.ThemeColors !== null && this.localChartEntity.ThemeColors !== undefined) {
        this.monoColor = this.localChartEntity.ThemeColors[0].Color;
        this.oldLocalChartEntity.ChartColors = this.localChartEntity.ChartColors = DialogueThemesSettings.ChartColors;
      }
    }
    this.localChartEntity.LogoImageUrl = this.oldLocalChartEntity.LogoImageUrl = this.customThemeData.LogoImageUrl === null ? '' : this.customThemeData.LogoImageUrl;
    this.ChangeColorPattern(this.localChartEntity.IsMultiColorTheme);
    const navColorInfo: AppColorSettingsInfo = this.storeHelper.GetApplicationColorSettingsFromStore();
    this.navColor = navColorInfo.topNavBackgroundColor;
    this.unsavedModalPopUp = new VueModalPopUpViewModel(
      this.$t('UnsavedChanges').toString(),
      this.$t('EventSetting.UnsavedItems').toString(),
      true,
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('@/assets/Images/Icon-warning.svg'),
      this.$t('CancelButton').toString(),
      this.$t('SaveAndSwitch').toString(),
    );
    Root.emit('disableTabs', true);
    if(this.localChartEntity.BackgroundImageUrl === '') {
      this.backgroundColor = { hex: this.localChartEntity.BackgroundColor };
    }
    this.fontColor = { hex: this.localChartEntity.FontColor };
    Root.on('SetFeaturedImageIconUrl',(val: string)=> {
      this.SetBackgroundImageUrl(val);
    });
  }
  // To set the default colors of palette
  private DefaultPaletteColors() {
    this.palette1Color = { hex: this.localChartEntity.ChartColors[0]};
    this.palette2Color = { hex: this.localChartEntity.ChartColors[1]};
    this.palette3Color = { hex: this.localChartEntity.ChartColors[2]};
    this.palette4Color = { hex: this.localChartEntity.ChartColors[3]};
    this.palette5Color = { hex: this.localChartEntity.ChartColors[4]};
    this.palette6Color = { hex: this.localChartEntity.ChartColors[5]};
  }
  // To hide the custom theme panel
  private HideCustomThemePanel(val: boolean) {
    this.isPanelDirty = JSON.stringify(this.localChartEntity) === JSON.stringify(this.oldLocalChartEntity) ? false : true;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-unused-expressions
    this.isPanelDirty ? this.showVueModalPopUp = true : this.$emit('HideCustomThemePanel', val);
    const isDisabled = this.isPanelDirty ? true : false;
    Root.emit('disableTabs', isDisabled);
  }
  // To whether it is a multi or mono color palettes
  private ChangeColorPattern(val: boolean): void {
    this.localChartEntity.IsMultiColorTheme = val;
    if (val === true) {
      this.DefaultPaletteColors();
    } else {
      this.palette1Color = (this.localChartEntity.IsCustomTheme && !this.oldLocalChartEntity.IsMultiColorTheme) ? {hex: this.monoColor} : {hex: this.navColor};
    }
  }
  // To show only selected/clicked palette
  private TogglePalette(val: number) {
    this.HideAllPalattes();
    this.isPaletteSelected[val] = !this.isPaletteSelected[val];
  }
  // To generate the monocolor theme
  private GenerateSliceColors(base: string) {
    const chartColors: any[] = [];
    const length = this.localChartEntity.Chartdata !== null && this.localChartEntity.Chartdata !== undefined ? this.localChartEntity.Chartdata.length : 0;
    let i = 0;
    for (i = 0; i < length ; i += 1) {
      chartColors.push(Highcharts.color(base).brighten((i - 3) / length).get());
    }
    return chartColors;
  }
  // To hide all the palettes
  private HideAllPalattes(){
    for(let i = 0 ; i< this.isPaletteSelected.length;i++) {
      this.isPaletteSelected[i] = true;
    }
  }
  // Save the changes made
  private Submit() {
    if(this.ValidateCustomTheme(this.v$)) {
      this.Setobject();
      this.SaveAndUpdateCustomTheme();
    }
  }
  // Convert rgb to hex value
  private rgb2hex(rgb: string){
    const rgb1 = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return (rgb1 && rgb1.length === 4) ? '#' +('0' + parseInt(rgb1[1],10).toString(16)).slice(-2) +('0' + parseInt(rgb1[2],10).toString(16)).slice(-2) +('0' + parseInt(rgb1[3],10).toString(16)).slice(-2) : '';
  }
  // Set object to send to save and update API
  private Setobject() {
    this.localChartEntity.Colors = '';
    this.localChartEntity.IsCustomTheme = true;
    this.localChartEntity.ThemeName = this.localChartEntity.Name;
    this.localChartEntity.LogoImageUrl = this.localChartEntity.IncludeLogo ? this.localChartEntity.LogoImageUrl : '';
    for(let index=0;index<this.localChartEntity.ThemeColors.length;index++) {
      if(index===this.localChartEntity.ThemeColors.length-1) {
        this.localChartEntity.Colors+=this.localChartEntity.ThemeColors[index].Color;
      } else {
        this.localChartEntity.Colors+=this.localChartEntity.ThemeColors[index].Color+',';
      }
    }
  }

  // Save and update custom theme
  private SaveAndUpdateCustomTheme() {
    Root.emit('show-loader', true);
    FetchData('/ApplicationInstance/SaveOrUpdateCustomTheme',
      JSON.stringify({
        dialogueThemeDto: this.localChartEntity,
      }))
      .then(() => {
        this.disablePage = true;
        if(this.localChartEntity.Id > 0) {
          this.toasterMessage = this.$t('EventSetting.CustomTheme.SucessUpdateMessage').toString();
        }
        setTimeout(() => {
          Root.emit('show-loader', false);
          this.showToaster = true;
        }, this.toaterTimeOut);
        this.oldLocalChartEntity = JSON.parse(JSON.stringify(this.localChartEntity));
        this.isPanelDirty = false;
        setTimeout(() => {
          this.showToaster = false;
          this.$emit('HideCustomThemePanel', false);
          Root.emit('disableTabs', false);
        }, this.toasterDefaultTimeout);
        Root.emit('event-detail-dto-feature');
      })
      .catch(() => {
        Root.emit('show-loader', false);
      });
  }
  // Validate CustomTheme object return true and false
  private ValidateCustomTheme(val: any): boolean{
    val.$touch();
    this.localEventDetailViewModal.validationErrorFields=[];
    this.localEventDetailViewModal.isVisibleValidationSummary=false;
    if(this.localChartEntity.Name==='') {
      this.localEventDetailViewModal.isVisibleValidationSummary=true;
      this.localEventDetailViewModal.validationErrorFields.push(
        this.$t('EventSetting.CustomTheme.NameFieldEmpty').toString(),
      );
    }
    if(this.localChartEntity.IncludeLogo && this.localChartEntity.LogoImageUrl === '') {
      this.localEventDetailViewModal.isVisibleValidationSummary=true;
      this.localEventDetailViewModal.validationErrorFields.push(
        this.$t('EventSetting.CustomTheme.ProvideLogo').toString(),
      );
      this.includeLogoError = true;
    }
    if(this.localEventDetailViewModal.validationErrorFields.length > 0 ) {
      return false;
    } else {
      return true;
    }
  }
  // Discard the changes made
  private Discard(val: any) {
    val.$reset();
    this.includeLogoError = false;
    this.CloseValidationSummary(false);
    this.ChangeColorPattern(this.oldLocalChartEntity.IsMultiColorTheme);
    this.isPanelDirty = false;
    this.localChartEntity = JSON.parse(JSON.stringify(this.oldLocalChartEntity));
    this.state.localChartEntity = this.localChartEntity;
  }
  // setting the colors changed
  private SetChangedColors(paletteIndex: number,color: string) {
    this.localChartEntity.ThemeColors[paletteIndex].Color = color;
  }
  // close vue modal popup
  private CloseModelPopup() {
    this.showVueModalPopUp = false;
  }
  // save and switch modal popup
  private SaveAndSwitchTab() {
    this.Submit();
    this.CloseModelPopup();
  }
  // Hide and show the image process section
  private ShowImageProcessSection(): boolean {
    return this.showImageProcess;
  }
  // Click on logo image
  private ClickOnImage(val: boolean) {
    this.showImageProcess = val;
    this.showFeaturedImage = false;
    this.subModuleName = SubModulePrefix.IconImage;
    Root.emit('disableTabs', true);
  }
  // Click on background image
  private ClickOnImageBackGround(val: boolean) {
    this.showImageProcess = val;
    this.subModuleName = SubModulePrefix.BackgroundImage;
    this.showFeaturedImage = true;
  }
  // Get image logo url
  private GetImageUrl() {
    return (this.localChartEntity.LogoImageUrl === null ||this.localChartEntity.LogoImageUrl === undefined || this.localChartEntity.LogoImageUrl === '') ? this.missingImage : this.localChartEntity.LogoImageUrl;
  }
  // Get background image
  private GetFeaturedImageUrl() {
    return (this.localChartEntity.BackgroundImageUrl === undefined || this.localChartEntity.BackgroundImageUrl === '') ? this.featureMissingImage : this.localChartEntity.BackgroundImageUrl;
  }
  // Set background image icon url
  private SetBackgroundImageUrl(
    thumbUrl: string,
  ) {
    this.localChartEntity.BackgroundImageUrl = thumbUrl === undefined ? '' : thumbUrl;
  }
  // Get logo image url
  private GetLocalLogoImageUrl() {
    return this.localChartEntity.LogoImageUrl;
  }
  // Set logo image url
  private SetlogoUrl(imgurl: string, _mainMediaFullUrl: string, IsLogoUrl: boolean) {
    if(IsLogoUrl === undefined || IsLogoUrl === false) {
      this.localChartEntity.LogoImageUrl = imgurl;
      this.localChartEntity.LogoImageUrl = this.localChartEntity.LogoImageUrl.includes('data:image/png') ? '' : this.localChartEntity.LogoImageUrl;
      if(this.localChartEntity.LogoImageUrl === '') {
        this.includeLogoError = true;
        this.successMessage = false;
      } else {
        this.includeLogoError = false;
        this.successMessage = true;
      }
    }
  }
  // CheckFeaturedImage
  private CheckFeaturedImage() {
    return true;
  }
  // Get missingImage to full url
  private GetfeaturedimageFullUrl() {
    return this.missingImage;
  }
  // Close the error message box
  private CloseValidationSummary(visible: boolean) {
    this.localEventDetailViewModal.isVisibleValidationSummary = visible;
  }
  private GetLogoWarning() {
    return this.$t('EventSetting.CustomTheme.LogoWarning',{size: DialogueThemesSettings.DefaultLogoSize,height: DialogueThemesSettings.DefaultLogoHeight});
  }
  private SetLogoOldUrl() {
    this.localChartEntity.LogoImageUrl = this.oldLocalChartEntity.LogoImageUrl;
  }
  private SetFeatureOldUrl() {
    this.localChartEntity.BackgroundImageUrl = this.oldLocalChartEntity.BackgroundImageUrl;
  }
  // below watcher is used to check change in color whether it is multi or mono
  @Watch('palette1Color')
  private CheckChangeInPalette1(newColor: {hex: ''}) {
    if(this.localChartEntity.IsMultiColorTheme) {
      this.SetChangedColors(0,newColor.hex);
    } else {
      const mono: string[] = this.GenerateSliceColors(newColor.hex);
      const length = this.localChartEntity.ThemeColors !== null && this.localChartEntity.ThemeColors !== undefined ? this.localChartEntity.ThemeColors.length : 0;
      for (let i = 0; i < length; i++) {
        this.SetChangedColors(i,this.rgb2hex(mono[i]));
      }
      if(this.localChartEntity !== null && this.localChartEntity !== undefined && this.localChartEntity.ThemeColors !== null && this.localChartEntity.ThemeColors !== undefined) {
        this.localChartEntity.ThemeColors[0].Color = newColor.hex;
      }
    }
  }
  // below watcher is used to check change in palette colors
  @Watch('palette2Color')
  private CheckChangeInPalette2(newColor: { hex: '' }) {
    this.SetChangedColors(1,newColor.hex);
  }
  @Watch('palette3Color')
  private CheckChangeInPalette3(newColor: { hex: '' }) {
    this.SetChangedColors(2,newColor.hex);
  }
  @Watch('palette4Color')
  private CheckChangeInPalette4(newColor: { hex: '' }) {
    this.SetChangedColors(3,newColor.hex);
  }
  @Watch('palette5Color')
  private CheckChangeInPalette5(newColor: { hex: '' }) {
    this.SetChangedColors(4,newColor.hex);
  }
  @Watch('palette6Color')
  private CheckChangeInPalette6(newColor: { hex: '' }) {
    this.SetChangedColors(5,newColor.hex);
  }
  // To check change in font color palette
  @Watch('fontColor')
  private CheckChangeInFontColor(newColor: { hex: '' }) {
    this.localChartEntity.FontColor = newColor.hex;
  }
  // To check change in background color palette
  @Watch('backgroundColor')
  private CheckChangeInBackground(newColor: {hex: ''}) {
    this.localChartEntity.BackgroundColor = newColor.hex;
    this.localChartEntity.BackgroundImageUrl = '';
  }
  // To check any change in entity
  @Watch('localChartEntity',{deep: true})
  private ChangeInEntity(val: DialogueThemeEntity) {
    const booleanValue = (JSON.stringify(val) === JSON.stringify(this.oldLocalChartEntity)) ? false : true;
    this.isPanelDirty = booleanValue;
    this.localChartEntity = val;
  }
}
export default toNative(CreateCustomTheme);
