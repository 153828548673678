
import DialogueCharts from './DialogueCharts/DialogueCharts.vue';
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { DialogueThemeEntity } from '@/entities/DialogueTheme/DialogueThemeEntity';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import DialogueThemesEnum from '@/enums/DialogueThemesEnum';
import DialogueThemesSettings from '@/helper/DialogueThemesSettings';
import DeleteDialogueTheme from '@/entities/Emits/EventSettings/DialogueThemes/DeleteDialogueTheme';
import { Root, Store } from '@/main';
import ShowCustomThemePanel from '@/entities/Emits/EventSettings/DialogueThemes/ShowCustomThemePanel';
@Component({
  components: {
    DialogueCharts,
  },
})
class DialogueTheme extends Vue {
  // get props fom parent component
  @Prop() private dialogueTheme!: DialogueThemeEntity;
  @Prop() private appliedThemeId!: number;
  @Prop() private selectedThemeId!: number;

  // Local variables
  private localDialogueTheme: DialogueThemeEntity = DialogueThemeEntity.createEmpty();
  private isHoverBarVisible = false;
  private menuBarIcon = require('@/assets/Images/overflow-menu.svg');
  private isMenuVisible = false;
  private showCharts = false;
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private localSelectedThemeId = 0;
  private localAppliedThemeId = 0;
  private reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();

  private mounted() {
    // assign props in local vars
    this.localDialogueTheme = Object.assign({}, this.dialogueTheme);
    this.localAppliedThemeId = this.appliedThemeId;
    this.localSelectedThemeId = this.selectedThemeId;

    // Set default theme bassed on there ids
    if (this.localDialogueTheme.Id === DialogueThemesEnum.LightTheme) {
      this.localDialogueTheme.LogoImageUrl = this.reqInfo.LogoUriDarkMode;
    } else if (this.localDialogueTheme.Id === DialogueThemesEnum.DarkTheme) {
      this.localDialogueTheme.LogoImageUrl = this.reqInfo.LogoURI;
    } else if (this.localDialogueTheme.Id === DialogueThemesEnum.MultiColorTheme) {
      this.localDialogueTheme.LogoImageUrl = this.reqInfo.LogoUriDarkMode;
    }
    this.localDialogueTheme.BackgroundImageUrl = this.localDialogueTheme.BackgroundImageUrl === null ? '' : this.localDialogueTheme.BackgroundImageUrl;
    // Set data in localObject for charts
    this.localDialogueTheme.Chartdata = DialogueThemesSettings.PreviewChartsData;
    this.localDialogueTheme.ChartTitle =  this.$t('Title').toString();
    this.showCharts = true;
    Root.on('change-selected-theme', (num: number) => {
      this.localSelectedThemeId = num;
    });
  }
  private ShowCustomThemePanel() {
    const themeData: ShowCustomThemePanel = ShowCustomThemePanel.createEmpty();
    themeData.DialogueThemeData = JSON.parse(JSON.stringify(this.localDialogueTheme));
    themeData.Val = true;
    Root.emit('show-custom-theme-panel', themeData);
  }
  private ConfirmDelete() {
    const deletionData: DeleteDialogueTheme = DeleteDialogueTheme.createEmpty();
    deletionData.DeleteAppliedTheme = this.localDialogueTheme.Id === this.localAppliedThemeId ? true : false;
    deletionData.ThemeId = this.localDialogueTheme.Id;
    deletionData.Val = true;
    Root.emit('confirm-delete',deletionData);
  }
  // show or hide hover bar
  private ShowHideHoverBar(val: boolean) {
    this.isHoverBarVisible = val;
  }

  // Show or hide menu bar
  private ShowMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }

  @Watch('appliedThemeId')
  private ChangeAppliedThemeId(newVal: any ) {
    // set in local
    this.localAppliedThemeId= newVal;
  }

  @Watch('selectedThemeId')
  private ChangeSelectedThemeId(newVal: any ) {
    // set in local
    this.localSelectedThemeId = newVal;
  }

}
export default toNative(DialogueTheme);
