import { DialogueThemeEntity } from '@/entities/DialogueTheme/DialogueThemeEntity';
import { ApplicationInstanceModel } from '@/entities/EventSetting/ApplicationInstanceModel';
export default class DialogueThemeDTO {
  public static createEmpty(): DialogueThemeDTO {
    return new DialogueThemeDTO();
  }
  constructor(
    public dialogueTheme: DialogueThemeEntity[] = [],
    public appThemeId: number = 0,
    public eventDetail: ApplicationInstanceModel = ApplicationInstanceModel.createEmpty(),
    public SearchString: string = '',
  ) {}
}
