import { DialogueThemeEntity } from '@/entities/DialogueTheme/DialogueThemeEntity';

export default class ShowCustomThemePanel {
  public static createEmpty(): ShowCustomThemePanel {
    return new ShowCustomThemePanel();
  }
  constructor(
    public Val: boolean = false,
    public DialogueThemeData: DialogueThemeEntity = DialogueThemeEntity.createEmpty(),
  ) {}
}
