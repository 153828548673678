
import { Vue, Prop, Component, Watch, toNative } from 'vue-facing-decorator';
@Component({})
class ParticipantTicketAndQR extends Vue {
  @Prop() IsParticipantQRVisible!: boolean;
  private localIsParticipantQRVisible = false;
  private mounted() {
    this.localIsParticipantQRVisible = this.IsParticipantQRVisible;
  }
  private ChangeParticipantQRCheckBoxValue() {
    this.localIsParticipantQRVisible = !this.localIsParticipantQRVisible;
    this.$emit('change-participant-qr-value', this.localIsParticipantQRVisible);
  }
  @Watch('IsParticipantQRVisible')
  private ChangeIsParticipantQRVisible(val: boolean) {
    this.localIsParticipantQRVisible = val;
  }
}
export default toNative(ParticipantTicketAndQR);
