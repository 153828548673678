export class CardViewModel {
  public static createEmpty(): CardViewModel {
    return new CardViewModel();
  }
  constructor(
    public Id: number = 0,
    public SortOrder: number = 0,
    public IconUrl: string = '',
    public Title: string = '',
    public Description: string = '',
    public IsSelected: boolean = false,
    public IsDisabled: boolean = false,
    public HelpText: string = '',
    public HelpTextUrl: string = '',
    public IsExternalLink: boolean = false,
    public ControlType: number = 0, // this controlType is used for show controll (contact us to enable, check box, green tick etc) based on the cardEntity
    public IsVisible: boolean =  false,
    public Message?: string,
    public InputBoxData?: string,
    public ReadMoreLink?: string,
  ) { }
}
