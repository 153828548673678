import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-395e06e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preview-logo-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "title-container font-weight-bold" }
const _hoisted_4 = {
  key: 0,
  class: "main-chart-container"
}
const _hoisted_5 = { class: "chart-container-first" }
const _hoisted_6 = { class: "chart-container-second" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogueHighCharts = _resolveComponent("DialogueHighCharts")!

  return (_openBlock(), _createElementBlock("div", {
    class: "chart-outer-container fit-background-image border-radius-10",
    style: _normalizeStyle((_ctx.localChartData.BackgroundImageUrl!=='' && _ctx.localChartData.BackgroundImageUrl!== null) ?{'background-image':'url('+_ctx.localChartData.BackgroundImageUrl+')'}:{'background-color':_ctx.localChartData.BackgroundColor}),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ChangeSelectedTheme(_ctx.localChartData))),
    onTouchstart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ChangeSelectedTheme(_ctx.localChartData)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.localChartData.IncludeLogo)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "preview-logo-img",
            src: _ctx.localChartData.LogoImageUrl
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", {
        class: "theme-title",
        style: _normalizeStyle({'color':_ctx.localChartData.FontColor})
      }, _toDisplayString(_ctx.localChartData.ChartTitle), 5)
    ]),
    (_ctx.showCharts)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_DialogueHighCharts, {
              "chart-data": _ctx.localChartData,
              "chart-type": _ctx.chartTypePie
            }, null, 8, ["chart-data", "chart-type"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_DialogueHighCharts, {
              "chart-data": _ctx.localChartData,
              "chart-type": _ctx.chartTypeColumn
            }, null, 8, ["chart-data", "chart-type"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 36))
}