
export default class ShowToaster {
  public static createEmpty(): ShowToaster {
    return new ShowToaster();
  }
  constructor(
    public Message: string = '',
    public Val: boolean = false,
  ) {}
}
