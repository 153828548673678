
import { Component, Vue, toNative, Prop } from 'vue-facing-decorator';
import { toast, type ToastOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
@Component({components: {
  toast,
},
})
class Toaster extends Vue {
  @Prop() message!: string;
  @Prop() defaultTimeout!: number;

  private localMessage = '';
  private localDefaultTimeout = 0;

  private mounted() {
    this.localMessage = this.message!;
    this.localDefaultTimeout = this.defaultTimeout!;
    toast(this.localMessage, {
      autoClose: this.localDefaultTimeout,
      position: toast.POSITION.TOP_CENTER,
      theme: 'colored',
      type: 'success',
      hideProgressBar: true,
      closeButton: false,
    } as ToastOptions);
  }
}
export default toNative(Toaster);
