import { ThemeColors } from './ThemeColors';

export class DialogueThemeEntity {
  public static createEmpty(): DialogueThemeEntity {
    // entity of Dialogues theme
    return new DialogueThemeEntity();
  }
  constructor(
    public Id: number = 0,
    public ThemeName: string = '',
    public Name: string = '',
    public ApplicationId: number = 0,
    public FontColor: string = '',
    public IsDeleted: boolean = false,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public ThemeColors: ThemeColors[] = [],
    public BackgroundColor: string = '',
    public BackgroundImageUrl: string = '',
    public LogoImageUrl: string = '',
    public IncludeLogo: boolean = true,
    public IsDefault: boolean = false,
    public IsCustomTheme: boolean = false,
    public IsMultiColorTheme: boolean = true,
    public Chartdata: any[] = [],
    public ChartTitle: string = '',
    public ChartColors: string[] = [],
    public Colors: string = '',
  ) { }
}
