import { FontAwesomeIcon } from '@/entities/FontAwesomeViewModel';
import { Department } from '@/entities/Department';
import { WarningMessageBarViewModel } from '../WarningMessageBarViewModel';
import { EventVisibilityCards } from './EventSettingCardViewModel';
import { ApplicationInstanceModel } from './ApplicationInstanceModel';
import { VueModalPopUpViewModel } from '../VueModalPopUpViewModel';
import { WarningMessageWithButtonViewModel } from '../WarningMessageWithButtonViewModel';
import LanguagesViewModel from './LanguagesViewModel';
import CustomMenuDefaultTranslationsViewModel from './CustomMenuDefaultTranslationsViewModel';
import { t } from '@/main';

export class EventSettingInfo {
  public static createEmpty(): EventSettingInfo {
    return new EventSettingInfo();
  }
  constructor(
    public isMobileView = false,
    public isimageIconClicked = false,
    public showImageProcess = false,
    public FeaturedImageWidth = 640,
    public FeaturedImageHeight = 160,
    public isEnableFeaturedImage = false,
    public LeftPosition = '',
    public TopPosition = '',
    public readonly moduleName = 'Event',
    public previouslySelectedFontAwesomeIcon: FontAwesomeIcon = FontAwesomeIcon.createEmpty(),
    public ShowFeatureImageBelowTitle = false,
    public ImageUrl = '',
    public MainMediaFullUrl = '',
    public FeaturedImageUrl = '',
    public FeaturedOrginalImageUrl = '',
    public missingImage: string = require('@/assets/Images/add-icon-image.svg'),
    public featureMissingImage: string = require('@/assets/Images/icon-placeholder.svg'),
    public localItem = {
      Address: '',
      City:'',
      Longitude:0,
      Latitude:0,
    },
    public showMap = true,
    public Departments: Department[] =[],
    public TimeZones = [],
    public timeZone: string = '',
    public isTimezoneTooltipInfoVisible = false,
    public isGenerateCode = false,
    // validation summary
    public isVisibleValidationSummary = false,
    public isDepartmentTooltipInfoVisible = false,
    public isDemoEventTooltipInfoVisible = false,
    public showInlineLoader = false,
    public validationErrorFields: string[] = [],
    public loadingImage: string = require('@/assets/Images/ventla-loader.gif'),
    public regex = /<(.|\n)*?>/,
    public descriptionLengthAllowed = 500,
    public showDescriptionWarning = false,
    public copiedIcon: string = require('@/assets/Images/link_icon_small.svg'),
    public qrCodeIcon: string = require('@/assets/Images/QR_code_icon.svg'),
    public accessLinkInformationMessage: WarningMessageBarViewModel =
    WarningMessageBarViewModel.createEmpty(),
    public showWarningMessageBar = false,
    public startDateIdString = t('EventSetting.EventSettingStartDate'),
    public endDateIdString = t('EventSetting.EventSettingEndDate'),
    public maxDateTime = new Date(
      new Date().setDate(new Date().getDate() + 500000),
    ).toISOString(),
    public showQrCodeModalPopup = false,
    public headerContent = '',
    public buttonText = '',
    public qrCodeURL = '',
    public showCopiedMessage = false,
    public linkCopiedFadeoutTime = 1500,
    public noteText = '',
    public Card: EventVisibilityCards = new EventVisibilityCards(),
    public localEventVisibilityCards = Card.EventVisibilityCards,
    public showDates = false,
    public isEditMode = false,
    public fileName = '',
    public applicationInstanceModel: ApplicationInstanceModel =
    ApplicationInstanceModel.createEmpty(),
    public minDateTime = applicationInstanceModel.StartDate.toISOString(),
    public showModalPopup = false,
    public convertEventModelPopup: VueModalPopUpViewModel =
    VueModalPopUpViewModel.createEmpty(),
    public showEventAccessLinkWarningMessageBar = false,
    public eventAccessLinkWarningMessage: WarningMessageBarViewModel =
    WarningMessageBarViewModel.createEmpty(),
    public demoEventWarningMessage: WarningMessageWithButtonViewModel =
    WarningMessageWithButtonViewModel.createEmpty(),
    public freezeEventWarningMessage: WarningMessageWithButtonViewModel =
    WarningMessageWithButtonViewModel.createEmpty(),
    public createDemoEventOnly: WarningMessageWithButtonViewModel =
    WarningMessageWithButtonViewModel.createEmpty(),
    public isFreezeEvent = false,
    public isScreenDisable: boolean = false,
    public isCreateDemoOnly = false,
    public languages: LanguagesViewModel[] = [],
    public customMenuDefaultTranslations: CustomMenuDefaultTranslationsViewModel[] = [],
  ) { }
}
