
import { Component, toNative, Vue } from 'vue-facing-decorator';
import VueTabs from '@/components/Common/Tabs.vue';
import { EventSettingTabList } from '@/entities/EventSetting/EventSettingTabList';
import { TabViewModel } from '@/entities/TabViewModel';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import DialoguesTab from '../DialoguesTab/DialoguesTab.vue';
import CustomizeMenuItemTab from '../CustomizationTab/CustomizeMenuItemTab.vue';
import Toaster from '@/components/Common/Toaster.vue';
import loading from '@/components/Common/loading.vue';
import { Root, Store, t } from '@/main';
import ShowToaster from '@/entities/Emits/EventSettings/ShowToaster';
import ChatTab from '../Chat/ChatTab.vue';

@Component({
  components: {
    VueTabs,
    VueModalPopUp,
    Toaster,
    loading,
  },
})
class EventInformationTabs extends Vue {
  private tabViewModel: EventSettingTabList = new EventSettingTabList();
  private eventSettingTabEntityList: TabViewModel[] = this.tabViewModel.TabViewModels;
  private showVueModalPopUp = false;
  private unsavedModalPopUp: VueModalPopUpViewModel = VueModalPopUpViewModel.createEmpty();
  private oldTabValue = '';
  private newTabValue = '';
  private dummyVar = false;
  private inImageProcess = false;
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private showToaster = false;
  private showLoader = false;
  private toasterMessage = t('EventSetting.SuccessUpdateMessage').toString();
  private toasterDefaultTimeout = 3000;
  private loaderBorderColor = '';


  private mounted() {
    const appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
    if (appInfo.EnableDialogueThemes) {
      // Push dialogue tab data in tab list if feature is enable from super admin
      this.eventSettingTabEntityList.push({ Id: 3, ComponentName: DialoguesTab, Header: this.$t('EventSetting.Dialogues').toString(), IsDisabled: false });
    }
    if (appInfo.EnableCustomMenuNames) {
      // Push Customization tab data in tab list if feature is enable from super admin
      this.eventSettingTabEntityList.push({ Id: 4, ComponentName: CustomizeMenuItemTab, Header: this.$t('EventSetting.Customization').toString(), IsDisabled: false });
    }
    if (this.storeHelper.GetEnableMessagingfeatureFromStore()) {
      // Push Customization tab data in tab list if feature is enable from super admin
      this.eventSettingTabEntityList.push({ Id: 5, ComponentName: ChatTab, Header: t('ChatAndDiscussions').toString(), IsDisabled: false });
    }


    // sort by id tab list
    this.eventSettingTabEntityList = this.eventSettingTabEntityList.sort((a, b) => a.Id - b.Id);
    this.unsavedModalPopUp = new VueModalPopUpViewModel(
      this.$t('UnsavedChanges').toString(),
      this.$t('EventSetting.UnsavedItems').toString(),
      true,
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('@/assets/Images/Icon-warning.svg'),
      this.$t('CancelButton').toString(),
      this.$t('SaveAndSwitch').toString(),
    );
    Root.on('is-dirty', (val: any) => {
      this.dummyVar = val;
    });
    Root.on('disableTabs', (val: any) => {
      this.eventSettingTabEntityList.forEach((e) => {
        e.IsDisabled = val;
      });
    });
    Root.on('show-toaster', (toasterData: ShowToaster) => {
      this.showToaster = toasterData.Val;
      this.toasterMessage = (toasterData.Message === '' || toasterData.Message === undefined) ? this.$t('EventSetting.SuccessUpdateMessage').toString() : toasterData.Message;
    });
    Root.on('show-loader', (val: any) => {
      this.showLoader = val;
    });
    Root.on('save-features', () => {
      // show save message when user press save button from unsaved changes modal popup
      this.toasterMessage = this.$t('EventSetting.SuccessUpdateMessage').toString();
      this.showLoader = true;
      this.showToaster = true;
      setTimeout(() => {
        this.showLoader = false;
        this.showToaster = false;
      }, 3000);
    });
    Root.on('add-remove-chatanddiscussion-tab', (val: any) => {
      const index = this.eventSettingTabEntityList.findIndex((item) => item.Id === 5);
      if (val && index <= -1) {
        this.eventSettingTabEntityList.push({ Id: 5, ComponentName: ChatTab, Header: t('ChatAndDiscussions').toString(), IsDisabled: false });
      } else {
        // pop item where id  = 5
        if ( !val && index !== -1) {
          this.eventSettingTabEntityList.splice(index, 1); // Remove the item at the found i
        }
      }
    });
  }
  private selectedTab(tabData: any) {
    if (this.dummyVar) {
      // emit value for enable or disable background whenever unsaved changes popUp are their
      Root.emit('disable-background-event-setting', true);
      this.showVueModalPopUp = true;
      // this.newTabValue = tabData;
      this.newTabValue = tabData.tab.name;
    } else {
      // this.oldTabValue = tabData;
      this.oldTabValue = tabData.tab.name;
      // emit value for enable or disable background whenever unsaved changes popUp are their
      Root.emit('disable-background-event-setting', false);
    }
  }
  private SaveModelPopUp() {
    // emit of save tab's data when user press save button from unsaved changes modal popup
    switch (this.oldTabValue) {
    case 'Features & Integrations':
      Root.emit('save-features', false);
      break;
    case 'Event information':
      Root.emit('save-event-setting', false);
      break;
    case 'Dialogues':
      Root.emit('save-dialogue-themes', false);
      break;
    case 'Customization':
      Root.emit('save-custom-menu-names', false);
      break;
    default:
      break;
    }

    this.showVueModalPopUp = false;
    // emit value for enable or disable background whenever unsaved changes popUp are their
    Root.emit('disable-background-event-setting', false);
    this.dummyVar = false;
  }
  private CloseModelPopup() {
    Root.emit('select-previous-tab',this.oldTabValue);
    setTimeout(() => {
      this.showVueModalPopUp = false;
      // emit value for enable or disable background whenever unsaved changes popUp are their
      Root.emit('disable-background-event-setting', false);
    }, 50);
  }
}
export default toNative(EventInformationTabs);
