export default class DeleteDialogueTheme {
  public static createEmpty(): DeleteDialogueTheme {
    return new DeleteDialogueTheme();
  }
  constructor(
    public Val: boolean = false,
    public ThemeId: number = 0,
    public DeleteAppliedTheme: boolean = false,
  ) {}
}
