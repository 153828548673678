
import {Vue, Prop, Component, Watch, toNative } from 'vue-facing-decorator';
import { DialogueThemeEntity } from '@/entities/DialogueTheme/DialogueThemeEntity';
import DialogueThemesSettings from '@/helper/DialogueThemesSettings';
import DialogueHighCharts from '@/components/Common/DialogueHighCharts.vue';
import { Root } from '@/main';
@Component({
  components: {
    DialogueHighCharts,
  },
})
class DialogueCharts extends Vue {
  // get props fom parent component
  @Prop() chartData!: DialogueThemeEntity;

  // local variables
  private localChartData: DialogueThemeEntity = DialogueThemeEntity.createEmpty();
  private showCharts = false;
  private chartTypePie = DialogueThemesSettings.PieChart;
  private chartTypeColumn = DialogueThemesSettings.ColumnChart;
  private mounted() {
    if (this.chartData !== null && this.chartData !== undefined) {
      // assign props data in local
      this.localChartData = Object.assign({}, this.chartData);
      this.showCharts = true;
    }
  }
  // this function checks whether the logo url does not contain the place holder icon
  private CheckLogoImage() {
    if(this.localChartData.LogoImageUrl.includes('icon-placeholder')) {
      return '';
    } else {
      return this.localChartData.LogoImageUrl;
    }
  }
  private ChangeSelectedTheme(item: DialogueThemeEntity){
    // emit id of change selected theme
    Root.emit('change-selected-theme',item.Id);
  }
  @Watch('chartData',{deep: true})
  private CheckChangeInEntity(newData: any){
    this.localChartData = newData;
  }
}
export default toNative(DialogueCharts);
