
import { Component, toNative, Vue } from 'vue-facing-decorator';
import ImageProcess from '@/components/Common/ImageProcess.vue';
import Datepicker from '@/components/Common/Datepicker.vue';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { StoreHelper } from '@/store/StoreHelper';
import MapPicker from '@/components/Common/MapPicker.vue';
import { EventSettingInfo } from '@/entities/EventSetting/EventSettingInfo';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { ApplicationInstanceModel } from '@/entities/EventSetting/ApplicationInstanceModel';
import moment from 'moment-timezone';
import Common from '@/helper/Common';
import Card from '@/components/Common/Card.vue';
import EventVisibility from '@/enums/EventVisibility';
import WarningMessageBar from '@/components/Common/WarningMessageBar.vue';
import WarningMessageWithButton from './WarningMessageWithButton.vue';
import { WarningMessageBarViewModel } from '@/entities/WarningMessageBarViewModel';
import SelfCheckIn from './SelfCheckIn.vue';
import Advertisement from './Advertisement.vue';
import ApplicationInstanceEventType from '@/enums/ApplicationInstanceEventTypeEnum';
import QRCodePopUp from '@/components/Common/QRCodePopUp.vue';
import { CardViewModel } from '@/entities/CardViewModel';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import {VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';
import { WarningMessageWithButtonViewModel } from '@/entities/WarningMessageWithButtonViewModel';
import VueModalPopModule from '@/enums/VueModalPopModule';
import EventInstanceState from '@/enums/EventInstanceState';
import { AdvertisementViewModel } from '@/entities/AdvertisementViewModel';
import { DialogueThemeEntity } from '@/entities/DialogueTheme/DialogueThemeEntity';
import loading from '@/components/Common/loading.vue';
import { reactive } from 'vue';
import ParticipantTicketAndQR from './ParticipantTicketAndQR.vue';
import useVuelidate from '@vuelidate/core';
import { EnvironmentBaseURL, FetchData, Root, Store } from '@/main';
import { minLength, required } from '@vuelidate/validators';
import DialogueThemeDTO from '@/entities/Emits/EventSettings/DialogueThemes/DialogueThemeDTO';
import CustomMenuDTO from '@/entities/Emits/EventSettings/CustomMenu/CustomMenuDTO';
import ShowToaster from '@/entities/Emits/EventSettings/ShowToaster';
import SubModulePrefix from '@/enums/SubModulePrefix';

@Component({
  components: {
    ImageProcess,
    Datepicker,
    MapPicker,
    ValidationSummary,
    Card,
    WarningMessageBar,
    WarningMessageWithButton,
    SelfCheckIn,
    QRCodePopUp,
    vSelect,
    VueModalPopUp,
    Advertisement,
    loading,
    ParticipantTicketAndQR,
  },
})
class EventDetail extends Vue {
  // constants
  private localEventDetailViewModal: EventSettingInfo = EventSettingInfo.createEmpty();
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
  private ValidationViewModel: ApplicationInstanceModel =this.localEventDetailViewModal.applicationInstanceModel;
  private modalPopupModule: any;
  private oldLocalapplicationInstanceModel: ApplicationInstanceModel = ApplicationInstanceModel.createEmpty();
  private isDirtyObject = true;
  private switchOnAnotherTab = true;
  private CurrentImageIndex = 0;
  private regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[aA-zZ\u00a1-\uffff0-9]-*)*[aA-zZ\u00a1-\uffff0-9]+)(?:\.(?:[aA-zZ\u00a1-\uffff0-9]-*)*[aA-zZ\u00a1-\uffff0-9]+)*(?:\.(?:[aA-zZ\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  private dummyAdvertisement: any[] = [];
  private isLoaded = false;
  private ShowEventSettingRegenratedMessage = false;
  private oldSelectedVisibilityType = 0;
  private showEventVsibilityCards = false;
  private isEnableIncludeEventCode = false;
  private saveEvent = true;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private dialogueThemes: DialogueThemeEntity[] = [];
  private clickedOnSaved = false;
  private loaderBorderColor = '';
  private autoRedirecttoMobileAppString = '&autoredirecttomobileapp=true';
  private subModuleName = SubModulePrefix.IconImage;
  private downloadAccessInstructionLink = 'https://support.ventla.io/kb/useful-resources/instruction-templates';

  // variables for validation
  private state = reactive({
    ValidationViewModel: ApplicationInstanceModel.createEmpty(),
  });

  private rules = {
    ValidationViewModel: {
      Name: {
        required,
        minLength: minLength(1),
      },
      ApplicationCode: {
        required,
        minLength: minLength(1),
      },
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);
  // Methods
  private mounted() {
    Root.emit('show-loader', true);
    this.localEventDetailViewModal.isEditMode =
      this.reqInfo.ApplicationInstanceId > 0 ? true : false;
    if (!this.localEventDetailViewModal.isEditMode) {
      this.localEventDetailViewModal.showEventAccessLinkWarningMessageBar =
        true;
      this.localEventDetailViewModal.eventAccessLinkWarningMessage =
        new WarningMessageBarViewModel(
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          require('@/assets/Images/info-icon.svg'),
          this.$t('EventSetting.eventAccessLinkWarningMessage').toString(),
          this.$t('EventSetting.EventInformation').toString(),
          false,
        );
    }
    this.localEventDetailViewModal.demoEventWarningMessage =
        new WarningMessageWithButtonViewModel(
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          this.$t('EventSetting.ConvertDemoEventText').toString(),
          this.$t('Button.Convert').toString(),
        );
    this.localEventDetailViewModal.accessLinkInformationMessage =
      new WarningMessageBarViewModel(
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        require('@/assets/Images/info-icon.svg'),
        this.$t('EventSetting.accessLinkInformationMessage').toString(),
        this.$t('EventSetting.EventInformation').toString(),
        true,
      );
    if (window.innerWidth <= 767) {
      this.localEventDetailViewModal.isMobileView = true;
    } else {
      this.localEventDetailViewModal.isMobileView = false;
    }
    this.GetDetailApplicationInstanceViewModel();
    this.state.ValidationViewModel = this.ValidationViewModel;
    // emit value for enable or disable background whenever unsaved changes popUp are their
    Root.on('disable-background-event-setting', (val: boolean) => {
      this.localEventDetailViewModal.isScreenDisable = val;
    });
    // save event information tab data when user press save button from unsaved changes modal popup
    Root.on('save-event-setting', (val: boolean) => {
      this.switchOnAnotherTab = val;
      this.Submit(this.v$);
    });
    if(this.localEventDetailViewModal.Departments.length === 0) {
      this.GetDepartments();
    }
    Root.on('reload-eventsettingtab', () => {
      this.reqInfo = this.storeHelper.GetRequestInfoFromStore();
      this.CreateNewDemoOnlyEvent();
    });
    // get updated data from api
    Root.on('event-detail-dto-feature', () => {
      this.GetDetailApplicationInstanceViewModel();
    });
    Root.on('SetFeaturedImageIconUrl',(val: string)=> {
      this.SetFeaturedImageIconUrl(val);
    });
  }
  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.localEventDetailViewModal.isVisibleValidationSummary = visible;
  }
  private ClickOnImage(val: boolean) {
    this.localEventDetailViewModal.isEnableFeaturedImage = false;
    this.localEventDetailViewModal.isimageIconClicked = true;
    this.localEventDetailViewModal.showImageProcess = val;
    this.subModuleName = SubModulePrefix.IconImage;
  }
  private ShowImageProcessSection() {
    return this.localEventDetailViewModal.showImageProcess;
  }
  private ShowImageProcess(index: number) {
    this.localEventDetailViewModal.FeaturedImageUrl = this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel[index].BannerUrl;
    this.localEventDetailViewModal.FeaturedOrginalImageUrl = this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel[index].BannerUrlOriginal;
    this.CurrentImageIndex = index;
    this.localEventDetailViewModal.isEnableFeaturedImage = true;
    this.localEventDetailViewModal.showImageProcess = true;
    this.subModuleName = SubModulePrefix.BackgroundImage;
  }
  // Show event date section
  private ShowEventDate() {
    return this.localEventDetailViewModal.applicationInstanceModel.StartDate !== null && this.localEventDetailViewModal.applicationInstanceModel.EndDate !== null;
  }
  private GoToEventListingPage() {
    window.location.href =
      EnvironmentBaseURL +
      '/Admin/Auth/ChangeApplicationInstance';
  }
  private GetDetailApplicationInstanceViewModel() {
    FetchData('/ApplicationInstance/GetDetailApplicationInstanceViewModel', '')
      .then((response) => {
        setTimeout(() => {
          Root.emit('show-loader', false);

        }, 500);
        this.localEventDetailViewModal.TimeZones = response.TimeZones;
        this.reqInfo.isApplicationLegacyModel =
          response.IsApplicationLegacyModel;
        if (response.ApplicationInstance !== null && response.ApplicationInstance !== undefined) {
          this.UpdateModel(response);
          Root.emit(
            'event-detail-dto',
            this.localEventDetailViewModal.applicationInstanceModel,
          );
          const dialoguesData: DialogueThemeDTO = DialogueThemeDTO.createEmpty();
          dialoguesData.appThemeId = this.localEventDetailViewModal.applicationInstanceModel.DialogueThemeId;
          dialoguesData.dialogueTheme = this.dialogueThemes;
          dialoguesData.eventDetail = this.localEventDetailViewModal.applicationInstanceModel;
          dialoguesData.SearchString = '';
          Root.emit(
            'dialogue-themes-dto',
            dialoguesData,
          );
          const customMenuData: CustomMenuDTO = CustomMenuDTO.createEmpty();
          customMenuData.languages = this.localEventDetailViewModal.languages;
          customMenuData.customMenuDefaultTranslations = this.localEventDetailViewModal.customMenuDefaultTranslations;
          customMenuData.eventDetails = this.localEventDetailViewModal.applicationInstanceModel;
          Root.emit(
            'custom-menu-dto',
            customMenuData,
          );
          this.isLoaded = true;
        }
        this.SelectEventType(this.localEventDetailViewModal.applicationInstanceModel);
        this.SetAssignDateTimeCheckbox();
        if (
          this.localEventDetailViewModal.applicationInstanceModel.Id > 0 &&
          (this.localEventDetailViewModal.applicationInstanceModel
            .IanaTimeZone !== null ||
            this.localEventDetailViewModal.applicationInstanceModel
              .IanaTimeZone !== '' ||
            this.localEventDetailViewModal.applicationInstanceModel
              .IanaTimeZone !== undefined)
        ) {
          this.localEventDetailViewModal.applicationInstanceModel.IanaTimeZone =
            this.localEventDetailViewModal.applicationInstanceModel.IanaTimeZone;
        } else {
          this.localEventDetailViewModal.applicationInstanceModel.IanaTimeZone =
            Common.GetTimeZoneName(moment.tz.guess());
        }
        this.ChangeLocationType(
          this.localEventDetailViewModal.applicationInstanceModel.LocationType,
        );
        if (
          response.SelectionMode === 'EventCodeAndTicket'
        ) {
          let requiredTicketSelect = false;
          this.localEventDetailViewModal.localEventVisibilityCards.forEach(
            (element) => {
              if (element.Id === EventVisibility.Private ) {
                element.IsVisible = true;
                element.SortOrder = 1;
                if (!(response.ApplicationInstance !== null && response.ApplicationInstance !== undefined &&  response.ApplicationInstance.Id > 0)) {
                  element.IsSelected = true;
                  this.localEventDetailViewModal.isGenerateCode = true;
                  this.localEventDetailViewModal.applicationInstanceModel.IsVisibilityPrivate = true;
                  this.localEventDetailViewModal.applicationInstanceModel.RequiredEventTicket = false;
                } else {
                  if (!response.ApplicationInstance.IsVisibilityPrivate) {
                    this.localEventDetailViewModal.applicationInstanceModel.IsVisibilityPrivate = false;
                    this.localEventDetailViewModal.applicationInstanceModel.RequiredEventTicket = true;
                    this.localEventDetailViewModal.isGenerateCode = false;
                    requiredTicketSelect = true;
                  }
                }
              } else if (element.Id === EventVisibility.RequiredTicket ) {
                element.IsVisible = true;
                element.SortOrder = 2;
                if (requiredTicketSelect) {
                  element.IsSelected = true;
                }
              } else {
                element.SortOrder = 3;
                element.IsSelected = false;
              }
            },
          );
        } else if (
          response.SelectionMode === 'EventCodeAndPublic'
        ) {
          this.localEventDetailViewModal.localEventVisibilityCards.forEach(
            (element) => {
              if (element.Id === EventVisibility.Public) {
                element.IsVisible = true;
              }
            },
          );
        } else if (
          response.SelectionMode === 'EventCodeOnly'
        ) {
          this.localEventDetailViewModal.localEventVisibilityCards.forEach(
            (element) => {
              if (element.Id === EventVisibility.Public) {
                element.SortOrder = 2;
              } else  if (element.Id === EventVisibility.Private) {
                element.IsSelected = true;
                this.localEventDetailViewModal.isGenerateCode = true;
                this.localEventDetailViewModal.applicationInstanceModel.IsVisibilityPrivate = true;
                this.localEventDetailViewModal.applicationInstanceModel.RequiredEventTicket = false;
                element.SortOrder = 1;
              } else {
                element.SortOrder = 3;
              }
            },
          );
        } else if (
          response.SelectionMode === 'EventCodeAndTicketAndPublic'
        ) {
          this.localEventDetailViewModal.localEventVisibilityCards.forEach(
            (element) => {
              if (element.Id === EventVisibility.Private ) {
                element.IsVisible = true;
                element.SortOrder = 2;
              } else if (element.Id === EventVisibility.RequiredTicket ) {
                element.IsVisible = true;
                element.SortOrder = 3;
              } else {
                element.SortOrder = 1;
                element.IsVisible = true;
              }
            },
          );
        }
        this.localEventDetailViewModal.localEventVisibilityCards = this.localEventDetailViewModal.localEventVisibilityCards.sort(function(a, b) {
          return a.SortOrder - b.SortOrder;
        });
        this.showEventVsibilityCards = true;
        if (this.localEventDetailViewModal.applicationInstanceModel.State === EventInstanceState.Freezed) {

          this.localEventDetailViewModal.freezeEventWarningMessage = this.reqInfo.IsSuperAdmin ? new WarningMessageWithButtonViewModel(
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            this.$t('EventSetting.FreezeEventMessage').toString(), this.$t('EventSetting.UnfreezeEvent').toString()) : new WarningMessageWithButtonViewModel(
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            this.$t('EventSetting.FreezeEventMessage').toString(),'') ;
          this.localEventDetailViewModal.isFreezeEvent = true;
          this.SetSecondTabDisabledEnabled();
        }
        if (this.localEventDetailViewModal.applicationInstanceModel.State === EventInstanceState.Archived) {
          this.localEventDetailViewModal.freezeEventWarningMessage =
        new WarningMessageWithButtonViewModel(
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          this.$t('EventSetting.ArchivedEventMessage').toString(),
          '',
        );
          this.localEventDetailViewModal.isFreezeEvent = true;
          this.SetSecondTabDisabledEnabled();
        }
        this.oldLocalapplicationInstanceModel = structuredClone(response.ApplicationInstance);
        Root.emit('hide-loader');
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('error');
      });
  }
  private SelectEventType(applicationInstanceModel: ApplicationInstanceModel) {
    if(applicationInstanceModel
      .RequiredEventTicket===true&&
            applicationInstanceModel.Id>0) {
      this.SetEntityModelForEventType(EventVisibility.RequiredTicket, false);
    } else if(applicationInstanceModel
      .ApplicationCode!==''&&
            applicationInstanceModel
              .ApplicationCode!==undefined&&
           applicationInstanceModel.Id>0) {
      this.SetEntityModelForEventType(EventVisibility.Private, false);
    } else {
      this.SetEntityModelForEventType(EventVisibility.Public, false);
    }
  }
  private SetSecondTabDisabledEnabled() {
    const el = document.querySelector('a[href*=\'#Features & Integrations\']');
    if(el !== null){
      if(this.localEventDetailViewModal.isFreezeEvent){
        el.classList.add('disabledpage');
      } else {
        el.classList.remove('disabledpage');
      }
    }
    const elem  = document.querySelector('a[href*=\'#Dialogues\']');
    if(elem !== null){
      if(this.localEventDetailViewModal.isFreezeEvent){
        elem.classList.add('disabledpage');
      } else {
        elem.classList.remove('disabledpage');
      }
    }
    const customMenuTab  = document.querySelector('a[href*=\'#Customization\']');
    if(customMenuTab !== null){
      if(this.localEventDetailViewModal.isFreezeEvent){
        customMenuTab.classList.add('disabledpage');
      } else {
        customMenuTab.classList.remove('disabledpage');
      }
    }
    const chatTab  = document.querySelector('a[href*=\'#Chat\']');
    if(chatTab !== null){
      if(this.localEventDetailViewModal.isFreezeEvent){
        chatTab.classList.add('disabledpage');
      } else {
        chatTab.classList.remove('disabledpage');
      }
    }
  }
  private SetAssignDateTimeCheckbox() {
    if (
      this.localEventDetailViewModal.applicationInstanceModel.StartDate !== null ||
      this.localEventDetailViewModal.applicationInstanceModel.EndDate !== null
    ) {
      this.localEventDetailViewModal.applicationInstanceModel.IsDatesShouldbeVisible = true;
      this.localEventDetailViewModal.showDates = true;
    } else {
      this.localEventDetailViewModal.showDates = false;
      this.localEventDetailViewModal.applicationInstanceModel.IsDatesShouldbeVisible = false;
    }
  }
  private ChangeAssignEventDate() {
    if (this.localEventDetailViewModal.applicationInstanceModel.IsDatesShouldbeVisible) {
      this.localEventDetailViewModal.applicationInstanceModel.StartDate = new Date();
      this.localEventDetailViewModal.applicationInstanceModel.EndDate = new Date();
    } else {
      this.localEventDetailViewModal.applicationInstanceModel.StartDate = null as any;
      this.localEventDetailViewModal.applicationInstanceModel.EndDate = null as any;
    }
    this.localEventDetailViewModal.showDates = this.localEventDetailViewModal.applicationInstanceModel.IsDatesShouldbeVisible;
  }
  private GetImageUrl() {
    return this.localEventDetailViewModal.applicationInstanceModel.ListLogoUrl === '' ||
      this.localEventDetailViewModal.applicationInstanceModel.ListLogoUrl ===
        '/content/images/ImageMissing.png'
      ? this.localEventDetailViewModal.missingImage
      : this.localEventDetailViewModal.applicationInstanceModel.ListLogoUrl;
  }
  private GetfeaturedimageFullUrl() {
    return this.localEventDetailViewModal.missingImage;
  }
  private GetFeaturedImageUrl() {
    return this.localEventDetailViewModal.FeaturedImageUrl === ''
      ? this.localEventDetailViewModal.featureMissingImage
      : this.localEventDetailViewModal.FeaturedImageUrl;
  }
  private GetImageFullUrl() {
    return this.localEventDetailViewModal.applicationInstanceModel.ListLogoUrl === '' || this.localEventDetailViewModal.applicationInstanceModel.ListLogoUrl === null ? this.localEventDetailViewModal.missingImage: this.localEventDetailViewModal.applicationInstanceModel.ListLogoUrl;
  }
  private CheckFeaturedImage() {
    return true;
  }
  private SetImageIconUrl(imgurl: string, mainMediaFullUrl: string, IsImageIconUrl: boolean) {
    if(IsImageIconUrl === undefined || IsImageIconUrl === false) {
      if(!this.localEventDetailViewModal.isEnableFeaturedImage) {
        this.localEventDetailViewModal.applicationInstanceModel.ListLogoUrl = imgurl;
        this.localEventDetailViewModal.MainMediaFullUrl = mainMediaFullUrl;
      }
    }
  }
  // set featured image url in local program item when featured image modified.
  // this is the emit property from image process component
  private SetFeaturedImageIconUrl(
    thumbUrl: string,
  ) {
    this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel[this.CurrentImageIndex].BannerUrl = thumbUrl;
    this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel[this.CurrentImageIndex].BannerUrlOriginal = thumbUrl;
  }
  private ShowProgramtitleBelowFeaturedImage(
    isShowProgramtitleBelowFeaturedImage: boolean,
  ) {
    this.localEventDetailViewModal.ShowFeatureImageBelowTitle =
      isShowProgramtitleBelowFeaturedImage;
  }
  private GetStartDate(): Date {
    return this.localEventDetailViewModal.applicationInstanceModel.StartDate;
  }
  private GetEndDate(): Date {
    return this.localEventDetailViewModal.applicationInstanceModel.EndDate;
  }
  private CheckTimeFormatStartDate(): boolean {
    const browsersettings: BrowserSettingsInfo =
      this.storeHelper.GetBrowserSettingsFromStore();
    return browsersettings.isFullDayTimeFormat;
  }
  private CheckTimeFormatEndDate(): boolean {
    const browsersettings: BrowserSettingsInfo =
      this.storeHelper.GetBrowserSettingsFromStore();
    return browsersettings.isFullDayTimeFormat;
  }
  // Assign date value to start date returned from datepicker component
  private SetBrowserDateValue(date: Date) {
    if (date != null) {
      this.localEventDetailViewModal.applicationInstanceModel.StartDate = date;
      if (
        new Date(this.localEventDetailViewModal.applicationInstanceModel.StartDate) >
        new Date(this.localEventDetailViewModal.applicationInstanceModel.EndDate)
      ) {
        this.localEventDetailViewModal.applicationInstanceModel.EndDate = date;
      }
      this.localEventDetailViewModal.minDateTime = new Date(date).toISOString();
      // this.alreadyselectedDate = date;
    }
  }
  // Assign date value to End date returned from datepicker component
  private SetBrowserDateValueEnd(date: Date) {
    if (date != null) {
      this.localEventDetailViewModal.applicationInstanceModel.EndDate = date;
    }
  }
  // Show/Hide the Address based on Location type
  private ChangeLocationType(val: number) {
    this.localEventDetailViewModal.applicationInstanceModel.LocationType = val;
    if (val === ApplicationInstanceEventType.Virtual) {
      this.localEventDetailViewModal.showMap = false;
    } else {
      this.localEventDetailViewModal.showMap = true;
    }
  }
  // Show hide the timezone tooltip
  private GetInformationString(val: boolean, type: string) {
    if (type === 'timeZone') {
      this.localEventDetailViewModal.isTimezoneTooltipInfoVisible = val;
    } else if (type === 'department') {
      this.localEventDetailViewModal.isDepartmentTooltipInfoVisible = val;
    } else if (type === 'DemoEvent') {
      this.localEventDetailViewModal.isDemoEventTooltipInfoVisible = val;
    }
  }

  // On submit
  private Submit(val: any) {
    this.clickedOnSaved = true;
    Common.ScrollToTop('main-container');
    this.localEventDetailViewModal.validationErrorFields = [];
    this.localEventDetailViewModal.isVisibleValidationSummary = false;

    if (this.localEventDetailViewModal.applicationInstanceModel.IsSuperAdminHiddenEvent && this.localEventDetailViewModal.applicationInstanceModel.DepartmentId > 0) {
      this.localEventDetailViewModal.validationErrorFields.push(
        this.$t('EventSetting.SuperAdminValidation').toString(),
      );
      this.localEventDetailViewModal.isVisibleValidationSummary = true;
      Root.emit('show-loader', false);

    }
    if (this.localEventDetailViewModal.applicationInstanceModel.Name === '') {
      this.localEventDetailViewModal.validationErrorFields.push(
        this.$t('EventSetting.Input.Event').toString(),
      );
      this.localEventDetailViewModal.isVisibleValidationSummary = true;
      Root.emit('show-loader', false);
    }
    if (this.localEventDetailViewModal.applicationInstanceModel.IsVisibilityPrivate) {
      if (this.localEventDetailViewModal.applicationInstanceModel.ApplicationCode === '') {
        this.localEventDetailViewModal.validationErrorFields.push(
          this.$t('EventSetting.Input.PrivateCode').toString(),
        );
        this.localEventDetailViewModal.isVisibleValidationSummary = true;
        Root.emit('show-loader', false);
      }
    }
    if (this.localEventDetailViewModal.isVisibleValidationSummary) {
      val.$touch();
      this.clickedOnSaved = false;
      return;
    }
    if (this.localEventDetailViewModal.applicationInstanceModel.LocationType === ApplicationInstanceEventType.Virtual) {
      this.localEventDetailViewModal.applicationInstanceModel.Address = '';
      this.localEventDetailViewModal.applicationInstanceModel.City = '';
      this.localEventDetailViewModal.applicationInstanceModel.Latitude = 0;
      this.localEventDetailViewModal.applicationInstanceModel.Longitude = 0 ;
    }

    if (!this.localEventDetailViewModal.applicationInstanceModel.IsVisibilityPrivate) {
      this.localEventDetailViewModal.applicationInstanceModel.ApplicationCode = '';
    }
    // Save event setting
    this.localEventDetailViewModal.applicationInstanceModel.RegistrationTemplateName =
      this.localEventDetailViewModal.applicationInstanceModel.RegistrationTemplateId.toString();
    if(this.UrlValidationAdvertisement() && this.ValidateBannerUrl()) {
      Root.emit('show-loader', true);
      this.SaveEventSetting();
    } else {
      this.clickedOnSaved = false;
    }
  }
  private ValidateBannerUrl(): boolean {
    let result = true;
    if(this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel !== null && this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel !== undefined) {
      this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel.forEach((element) => {
        if(element.IsDeleted === false) {
          if(element.BannerUrl === '' || element.BannerUrl === undefined || element.BannerUrl === null) {
            result = false;
            this.localEventDetailViewModal.validationErrorFields.push(
              this.$t('EventSetting.RequiredAdvertisementMessage').toString(),
            );
            this.localEventDetailViewModal.isVisibleValidationSummary = true;
            Root.emit('show-loader', false);
          }
        }
      });
    }
    return result;
  }
  private UrlValidationAdvertisement(): boolean {
    let result = true;
    if(this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel !== undefined && this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel !== null) {
      this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel.forEach((element) => {
        if(element.PageUrl !== '' && element.PageUrl !== undefined && element.PageUrl !== null) {
          if(!this.IsValidURL(element.PageUrl)) {
            result = false;
            this.localEventDetailViewModal.validationErrorFields.push(
              this.$t('InvalidUrl').toString(),
            );
            this.localEventDetailViewModal.isVisibleValidationSummary = true;
            Root.emit('show-loader', false);
          }
        }
      });
    }
    return result;
  }
  // Validate the Url string usign regex
  private IsValidURL(str: string): boolean {
    const regexp = this.regex;
    const result = regexp.test(str);
    return result;
  }
  // Save data into DB
  private SaveEventSetting() {

    const objForJson = {
      Latitude: this.localEventDetailViewModal.applicationInstanceModel.Latitude,
      Longitude: this.localEventDetailViewModal.applicationInstanceModel.Longitude,
    };
    FetchData('/ApplicationInstance/SaveOrEditApplicationInstance',
      JSON.stringify({
        applicationInstanceModel: this.localEventDetailViewModal.applicationInstanceModel,
        jsonData : JSON.stringify(objForJson),
      }))
      .then((response: any) => {

        if(response.error){
          this.localEventDetailViewModal.validationErrorFields.push(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            response.error,
          );
          this.localEventDetailViewModal.isVisibleValidationSummary = true;
          Root.emit('show-loader', false);
          Common.ScrollToTop('main-container');
          this.clickedOnSaved = false;
        } else{
          if(this.localEventDetailViewModal.applicationInstanceModel.Id === 0) {
            const link = EnvironmentBaseURL + '/Admin/Auth/ChangeApplicationInstanceAndNavigate?id=' + response.ApplicationInstance.Id + '&isCreateNewEvent=' + true;
            window.location.href= link;
          } else {
            if (
              response.ApplicationInstance !== null &&
          response.ApplicationInstance !== undefined
            ) {
              this.GetDetailApplicationInstanceViewModel();
              Root.emit('update-event-name', this.localEventDetailViewModal.applicationInstanceModel.Name);
              Root.emit('is-dirty',false);
            }
          }
          if(!this.reqInfo.IsApplicationOnNewPackage && this.switchOnAnotherTab) {
            this.GetDepartments();
          }
          const toasterData = ShowToaster.createEmpty();
          setTimeout(() => {
            toasterData.Val = true;
            Root.emit('show-toaster', toasterData);
            Root.emit('show-loader', false);
            setTimeout(() => {
              toasterData.Val = false;
              Root.emit('show-toaster', toasterData);
            }, 2000);
          }, 1000);
        }
      })
      .catch(() => {
        Root.emit('show-loader', false);
        this.clickedOnSaved = false;
      });
  }
  private UpdateModel(response: any) {
    this.localEventDetailViewModal.applicationInstanceModel = response.ApplicationInstance;
    this.localEventDetailViewModal.languages = response.Languages;
    this.localEventDetailViewModal.customMenuDefaultTranslations = response.CustomMenuDefaultTranslations;
    this.localEventDetailViewModal.applicationInstanceModel.QRCodeUrl=response.ApplicationInstance.QRCodeUrl;
    this.localEventDetailViewModal.applicationInstanceModel.EnableParticipantSelfCheckIn=response.ApplicationInstance.EnableParticipantSelfCheckIn;
    this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel = response.ApplicationInstance.AdvertisementViewModel;
    this.ValidationViewModel=response.ApplicationInstance;
    this.localEventDetailViewModal.applicationInstanceModel.EventAccessLinkAutoRedirectToMobileApp = response.ApplicationInstance.EventAccessLinkAutoRedirectToMobileApp;
    const oldAdvertisements: AdvertisementViewModel[] =  response.ApplicationInstance.AdvertisementViewModel;
    if(oldAdvertisements !== undefined && oldAdvertisements !== null){
      this.dummyAdvertisement = oldAdvertisements;
    }
    this.CheckTheQuotaMessageBar(this.localEventDetailViewModal.applicationInstanceModel.DepartmentId);
    this.oldLocalapplicationInstanceModel = structuredClone(this.localEventDetailViewModal.applicationInstanceModel);
    this.isDirtyObject = true;
    this.dialogueThemes = response.DialogueThemes;
    this.state.ValidationViewModel = this.ValidationViewModel;
  }

  // Hide show Change event access type
  private ChangeEventType(val: CardViewModel) {
    this.SetEntityModelForEventType(val.Id, true);
  }
  private SetEntityModelForEventType(val: number, showMessage: boolean) {
    if(showMessage) {
      if(this.localEventDetailViewModal.isEditMode && this.oldSelectedVisibilityType !== val && (this.oldSelectedVisibilityType === EventVisibility.Private || val === EventVisibility.Private)){
        this.ShowEventSettingRegenratedMessage = true;
        this.localEventDetailViewModal.eventAccessLinkWarningMessage =
        new WarningMessageBarViewModel(
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          require('@/assets/Images/info-icon.svg'),
          this.$t('EventSetting.RegenratedMessage').toString(),
          this.$t('EventSetting.EventInformation').toString(),
          false,
        );
        setTimeout(() => {
          this.ShowEventSettingRegenratedMessage = false;
        }, 5000);
      }
    }

    this.oldSelectedVisibilityType = val;
    this.localEventDetailViewModal.localEventVisibilityCards.forEach((element) => {
      if (element.Id === val) {
        if (element.IsDisabled === true) {
          element.IsSelected = false;
          return;
        }
        element.IsSelected = true;
      } else {
        element.IsSelected = false;
      }
    });
    if (val === EventVisibility.Private) {
      this.localEventDetailViewModal.isGenerateCode = true;
      this.localEventDetailViewModal.applicationInstanceModel.IsVisibilityPrivate = true;
      this.localEventDetailViewModal.applicationInstanceModel.RequiredEventTicket = false;
    } else if (val === EventVisibility.RequiredTicket) {
      this.localEventDetailViewModal.applicationInstanceModel.IsVisibilityPrivate = false;
      this.localEventDetailViewModal.applicationInstanceModel.RequiredEventTicket = true;
      this.localEventDetailViewModal.isGenerateCode = false;
    } else {
      this.localEventDetailViewModal.applicationInstanceModel.IsVisibilityPrivate = false;
      this.localEventDetailViewModal.applicationInstanceModel.RequiredEventTicket = false;
      this.localEventDetailViewModal.isGenerateCode = false;
    }
  }

  // Set Event Access Link
  private SetEventAccessLink() {
    let link = this.localEventDetailViewModal.applicationInstanceModel.EventInvitationLink;
    if (this.localEventDetailViewModal.applicationInstanceModel.ApplicationCode !== '' && this.localEventDetailViewModal.applicationInstanceModel.ApplicationCode !== undefined) {
      this.isEnableIncludeEventCode = false;
      link =
      this.localEventDetailViewModal.applicationInstanceModel.EventAccessLinkAutoRedirectToMobileApp ?
        link + this.autoRedirecttoMobileAppString : this.localEventDetailViewModal.applicationInstanceModel.EventInvitationLink;
      return link;
    } else {
      this.localEventDetailViewModal.applicationInstanceModel.IncludeEventCodeInLink = false;
      this.isEnableIncludeEventCode = true;
      link =
      this.localEventDetailViewModal.applicationInstanceModel.EventAccessLinkAutoRedirectToMobileApp ?
        link + this.autoRedirecttoMobileAppString : this.localEventDetailViewModal.applicationInstanceModel.EventInvitationLink;
      return link;
    }
  }
  // Hide and show the department quota waring message
  private OnDepartmentChange(object: any) {
    if (this.IsLoggedInUserSuperAdmin() || this.IsLoggedInUserApplicationAdmin()) {
      this.CheckTheQuotaMessageBar(object.target.value);
    }
  }
  private CheckTheQuotaMessageBar(Id: any) {
    if(!this.reqInfo.IsApplicationOnNewPackage) {
      const department = this.localEventDetailViewModal.Departments.find(
        (item) => item.Id.toString() === Id.toString(),
      );
      if(department !== undefined) {
        this.localEventDetailViewModal.applicationInstanceModel.DepartmentId =
        department.Id;
        this.localEventDetailViewModal.applicationInstanceModel.DepartmentName =
        department.Name;
        if (department.EventQuota <= department.PresentEventQuota) {
          this.localEventDetailViewModal.showDescriptionWarning = true;
          this.localEventDetailViewModal.applicationInstanceModel.IsQuotaExceed = true;
          return;
        } else {
          this.localEventDetailViewModal.applicationInstanceModel.IsQuotaExceed = false;
          this.localEventDetailViewModal.showDescriptionWarning = false;
        }
      } else {
        this.localEventDetailViewModal.applicationInstanceModel.IsQuotaExceed = false;
        this.localEventDetailViewModal.showDescriptionWarning = false;
      }
    }
  }
  private OnTimeZoneChange(object: any) {
    if (object !== null && object !== undefined) {
      this.localEventDetailViewModal.applicationInstanceModel.IanaTimeZone = object;
    } else {
      this.localEventDetailViewModal.applicationInstanceModel.IanaTimeZone =  Common.GetTimeZoneName(moment.tz.guess());
    }
  }

  private IconToolTip(val: string) {
    return val;
  }

  private WarningMessageBarCloseOperation() {
    this.localEventDetailViewModal.showWarningMessageBar = false;
  }
  // Genrate Private code
  private GenrateCode() {
    this.localEventDetailViewModal.showInlineLoader = true;
    const self = this;
    FetchData('/ApplicationInstance/GetAutoGeneratedEventPassword',
      JSON.stringify({}))
      .then((response: any) => {
        self.localEventDetailViewModal.showInlineLoader = false;
        self.localEventDetailViewModal.applicationInstanceModel.ApplicationCode = response.password;
        self.ValidationViewModel.ApplicationCode = response.password;
        self.state.ValidationViewModel = self.ValidationViewModel;
        this.IncludeEventCodeInLink();
        Root.emit('disableTabs', false);
      })
      .catch(() => {
        self.localEventDetailViewModal.showInlineLoader = false;
      });
  }
  // Get selfcheckin QRCode Image url
  private GetAndOpenQRCodeImageInPopup() {
    if (
      this.localEventDetailViewModal.applicationInstanceModel.QRCodeUrl !== '' &&
      this.localEventDetailViewModal.applicationInstanceModel.QRCodeUrl !== undefined
    ) {
      this.OpenQRCodeImageInPopup('selfcheckin');
    }
  }
  // Open QRCode Image In Popup for check-in
  private OpenQRCodeImageInPopup(type: string) {
    if (type === 'selfcheckin') {
      this.localEventDetailViewModal.headerContent = this.$t('EventSetting.SelfCheckInHeading').toString();
      this.localEventDetailViewModal.buttonText = this.$t('EventSetting.DownloadAsImageButtonText')
        .toString();
      this.localEventDetailViewModal.qrCodeURL = this.localEventDetailViewModal.applicationInstanceModel.QRCodeUrl;
      this.localEventDetailViewModal.showQrCodeModalPopup = true;
      this.localEventDetailViewModal.noteText = '';
      this.localEventDetailViewModal.fileName = this.$t('EventSetting.SelfCheckInQrCodeImageName').toString();
    } else {
      this.localEventDetailViewModal.headerContent = this.$t('EventSetting.EventAccessInHeading')
        .toString();
      this.localEventDetailViewModal.buttonText = this.$t('EventSetting.DownloadAsImageButtonText')
        .toString();
      this.GetEventAccessQrCodeImageUrl();
      this.localEventDetailViewModal.showQrCodeModalPopup = true;
      this.localEventDetailViewModal.noteText = this.$t('EventSetting.NoteText').toString();
      this.localEventDetailViewModal.fileName = this.$t('EventSetting.EventAccessQrCodeImageName').toString();
    }
  }
  private GetEventAccessQrCodeImageUrl() {
    FetchData('/Common/GenerateQRCode',
      JSON.stringify({
        input: this.SetEventAccessLink(),
      }))
      .then((response: any) => {
        this.localEventDetailViewModal.qrCodeURL = response.Result;
      })
      .catch(() => {
        // error
      });
  }
  // Close Popup
  private CloseQRCodePopup() {
    this.localEventDetailViewModal.showQrCodeModalPopup = false;
  }
  // Method to copy Link to Clipboard
  private CopyPublicLinkToClipboard() {
    this.localEventDetailViewModal.showCopiedMessage = Common.copyPublicLinkToClipboard(
      this.SetEventAccessLink(),
    );
    if (this.localEventDetailViewModal.showCopiedMessage) {
      setTimeout(() => {
        this.localEventDetailViewModal.showCopiedMessage = false;
      }, this.localEventDetailViewModal.linkCopiedFadeoutTime);
    }
  }
  // Select Location type
  private GetLocationTypeSelected(locationType: number) {
    if (locationType === this.localEventDetailViewModal.applicationInstanceModel.LocationType) {
      return true;
    } else {
      return false;
    }
  }
  // Change Value CheckIn EnableParticipantSelfCheckIn
  private ChangeValueCheckIn(val: boolean) {
    if (!val) {
      this.localEventDetailViewModal.applicationInstanceModel.QRCodeUrl = '';
    }
    this.localEventDetailViewModal.applicationInstanceModel.EnableParticipantSelfCheckIn = val;
  }
  private ChangeParticipantQRValue(val: boolean) {
    this.localEventDetailViewModal.applicationInstanceModel.IsParticipantQRVisible = val;
  }
  // Get ImageIcon ToolTip Message
  private GetImageIconToolTipMessage() {
    if (this.localEventDetailViewModal.isEditMode) {
      return this.$t('EventSetting.ToolTip.AddIcon');
    } else {
      return this.$t('EventSetting.ToolTip.AddIcon');
    }
  }
  // Check Is LoggedIn User Super Admin
  private IsLoggedInUserSuperAdmin() {
    if(this.reqInfo.IsSuperAdmin) {
      return true;
    }
    return false;
  }
  // Check Is LoggedIn User Department Admin
  private IsLoggedInUserDepartmentAdmin() {
    if(this.reqInfo.IsDepartmentAdmin) {
      return true;
    }
    return false;
  }
  // Check Is LoggedIn User Application Admin
  private IsLoggedInUserApplicationAdmin() {
    if(this.reqInfo.IsApplicationAdmin) {
      return true;
    }
    return false;
  }
  // Close Model Popup
  private CloseModelPopup() {
    this.localEventDetailViewModal.showModalPopup = false;
  }
  private ConvertDemoEvent() {
    if(this.CheckCanConvertToRegularEvent()) {
      this.localEventDetailViewModal.showModalPopup = true;
      this.localEventDetailViewModal.convertEventModelPopup = new VueModalPopUpViewModel(
        this.$t('EventSetting.ConvertToRegularEvent').toString(),
        this.$t('EventSetting.ConsumedPackagelimit').toString(),
        false,
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        require('@/assets/Images/info-icon.svg'),
        this.$t('Button.Close').toString(),
        '',
      );
      this.modalPopupModule =  VueModalPopModule.DemoEvent;
    } else {
      this.localEventDetailViewModal.showModalPopup = true;
      this.localEventDetailViewModal.convertEventModelPopup = new VueModalPopUpViewModel(
        this.$t('EventSetting.ConvertToRegularEvent').toString(),
        this.$t('EventSetting.ConvertEventIntoNormalEvent').toString(),
        false,
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        require('@/assets/Images/info-icon.svg'),
        this.$t('CancelButton').toString(),
        this.$t('YesButton').toString(),
      );
      this.modalPopupModule =  VueModalPopModule.DemoEvent;
    }
  }

  private ConvertFreezeEvent() {
    this.localEventDetailViewModal.showModalPopup = true;
    this.localEventDetailViewModal.convertEventModelPopup = new VueModalPopUpViewModel(
      this.$t('EventSetting.ConvertToRegularEvent').toString(),
      this.$t('EventSetting.ConvertEventIntoNormalEvent').toString(),
      false,
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('@/assets/Images/info-icon.svg'),
      this.$t('CancelButton').toString(),
      this.$t('YesButton').toString(),
    );
    this.modalPopupModule = VueModalPopModule.FreezeEvent;
  }

  private SaveModelPopUp() {
    switch (this.modalPopupModule) {
    case VueModalPopModule.DemoEvent:
      this.ConvertDemoEventToRegular();
      break;
    case VueModalPopModule.FreezeEvent:
      this.ConvertFreezeEventToUnFreezeEvent();
      break;
    default:
      break;
    }
  }
  private ShowDepartmentDropdown() {
    return this.reqInfo.IsDepartmentAdminFeatureEnable;
  }
  private ConvertDemoEventToRegular() {
    this.localEventDetailViewModal.showModalPopup = false;
    Root.emit('show-loader', true);
    FetchData('/ApplicationInstance/ConvertDemoToRegularEvent',
      JSON.stringify({}))
      .then((response: any) => {
        this.GetDepartments();
        if(response) {
          this.localEventDetailViewModal.applicationInstanceModel.IsDemoEvent = false;
          Root.emit('show-loader', false);
        } else {
          Root.emit('show-loader', false);
        }
      })
      .catch(() => {
        Root.emit('show-loader', false);
      });
  }
  private ConvertFreezeEventToUnFreezeEvent() {
    this.localEventDetailViewModal.showModalPopup = false;
    Root.emit('show-loader', true);
    FetchData('/ApplicationInstance/UnfreezeEvent',
      JSON.stringify({}))
      .then((response: any) => {
        if(response) {
          this.localEventDetailViewModal.applicationInstanceModel.State = EventInstanceState.Normal;
          this.oldLocalapplicationInstanceModel = structuredClone(this.localEventDetailViewModal.applicationInstanceModel);
          this.localEventDetailViewModal.isFreezeEvent = false;
          Root.emit('show-loader', false);
          this.SetSecondTabDisabledEnabled();
        } else {
          Root.emit('show-loader', false);
          this.SetSecondTabDisabledEnabled();
        }
      })
      .catch(() => {
        Root.emit('show-loader', false);
        this.SetSecondTabDisabledEnabled();
      });
  }

  private CheckEventIsDemoEvent(){
    if (this.localEventDetailViewModal.applicationInstanceModel.IsDemoEvent &&
        !this.reqInfo.isApplicationLegacyModel &&
        !this.reqInfo.IsApplicationOnNewPackage &&
        !this.localEventDetailViewModal.applicationInstanceModel.IsSuperAdminHiddenEvent
        && this.localEventDetailViewModal.applicationInstanceModel.Id > 0) {
      return true;
    }
    return false;
  }

  private CreateNewDemoOnlyEvent(): boolean {
    let result = false;
    if(!this.localEventDetailViewModal.isEditMode){
      result = this.CheckCreateDemoEventOnly();
    }
    if(result){
      this.localEventDetailViewModal.applicationInstanceModel.IsDemoEvent = result;
      this.localEventDetailViewModal.createDemoEventOnly =
        new WarningMessageWithButtonViewModel(
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          this.$t('EventSetting.CreateDemoOnlyMessage').toString(),
          '',
        );
    }
    this.localEventDetailViewModal.isCreateDemoOnly = result;
    return result;
  }

  private CheckCreateDemoEventOnly() {
    let result = false;
    if(this.reqInfo.IsApplicationOnNewPackage) {
      result=false;
    } else {
      if(this.reqInfo.ThrehsholdEventsNumber<=this.reqInfo.MaxAllowedAppInstances) {
        result=this.reqInfo.CurrentEventsNumber>=this.reqInfo.ThrehsholdEventsNumber;
      } else {
        result=this.reqInfo.TotalConsumedEvents>=this.reqInfo.MaxAllowedAppInstances;
      }
    }
    return result;
  }

  private CheckCanConvertToRegularEvent() {
    if (this.reqInfo.AdditionalEvents > 0 || this.reqInfo.IsApplicationOnNewPackage === true) {
      return false;
    } else {
      return true;
    }
  }

  // Check if isEditMode
  private IsNewEvent() {
    return this.localEventDetailViewModal.isEditMode;
  }
  // Check Is ApplicationL egacyModel
  private IsApplicationLegacyModel() {
    return this.reqInfo.isApplicationLegacyModel;
  }
  // Show Create Demo Event checkbox
  private ShowCreateDemoEvent() {
    return (!this.reqInfo.isApplicationLegacyModel &&
              !this.reqInfo.IsApplicationOnNewPackage &&
              this.reqInfo.ApplicationInstanceId === 0);
  }
  // Show OverrideParticipan tLimit checkbox
  private ShowOverrideParticipantLimit() {
    return (!this.reqInfo.isApplicationLegacyModel &&
                    this.IsLoggedInUserSuperAdmin());
  }
  // Disabled Department
  private DisabledDepartment() {
    return (this.localEventDetailViewModal.applicationInstanceModel.IsSuperAdminHiddenEvent && !this.IsLoggedInUserSuperAdmin());
  }
  // IncludeEventCodeInLink
  private IncludeEventCodeInLink() {
    if (this.localEventDetailViewModal.applicationInstanceModel.IncludeEventCodeInLink) {
      const link = this.localEventDetailViewModal.applicationInstanceModel.EventInvitationLinkWithoutCode;
      this.localEventDetailViewModal.applicationInstanceModel.EventInvitationLink = link + '&eventcode=' + this.localEventDetailViewModal.applicationInstanceModel.ApplicationCode;
    } else {
      this.localEventDetailViewModal.applicationInstanceModel.EventInvitationLink = this.localEventDetailViewModal.applicationInstanceModel.EventInvitationLinkWithoutCode;
    }
  }
  // handle Input On Keyup ApplicationCode Type
  private HandleInputOnKeyupApplicationCodeType() {
    this.localEventDetailViewModal.applicationInstanceModel.ApplicationCode = this.ValidationViewModel.ApplicationCode;
    this.localEventDetailViewModal.applicationInstanceModel.ApplicationCode = this.localEventDetailViewModal.applicationInstanceModel.ApplicationCode.toLowerCase();
    this.isEnableIncludeEventCode = false;
    this.IncludeEventCodeInLink();
  }

  // Genrate Private code
  private GetDepartments() {
    FetchData('/Application/GetDepartment',
      JSON.stringify({}))
      .then((response: any) => {
        this.localEventDetailViewModal.Departments = response;
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {
      });
  }
  // anything change in model
  private IsObjectDirty() {
    if (this.isLoaded) {
      this.localEventDetailViewModal.applicationInstanceModel.Name = this.ValidationViewModel.Name;
      const objectState = JSON.stringify(this.localEventDetailViewModal.applicationInstanceModel) === JSON.stringify(this.oldLocalapplicationInstanceModel);
      if (!objectState) {
        this.IsDirtyFlag();
        this.isTabDisable();
      }
    }
  }
  // Set dirty flag and emit
  private IsDirtyFlag() {
    this.isDirtyObject = false;
    this.$emit('is-dirty');
    Root.emit('is-dirty',true);
    this.isTabDisable();
  }
  private Discard() {
    this.localEventDetailViewModal.applicationInstanceModel = structuredClone(this.oldLocalapplicationInstanceModel);
    this.SelectEventType(this.localEventDetailViewModal.applicationInstanceModel);
    this.ChangeLocationType(this.localEventDetailViewModal.applicationInstanceModel.LocationType);
    Root.emit('event-detail-dto',this.localEventDetailViewModal.applicationInstanceModel);
    Root.emit('is-dirty',false);
    this.isDirtyObject = true;
    this.dummyAdvertisement.forEach((element): void => {
      element.IsDeleted = false;
      if (typeof element.Id === 'string') {
        element.IsDeleted = true;
      }
    });
    // Commented for dev-8979
    // this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel = JSON.parse(JSON.stringify(this.dummyAdvertisement));
    this.ValidationViewModel = Object.assign({}, this.oldLocalapplicationInstanceModel);
    this.state.ValidationViewModel = this.ValidationViewModel;
    Root.emit('disableTabs', false);
    Root.emit('hide-selfcheckin-warning-bar');
    this.localEventDetailViewModal.isVisibleValidationSummary = false;
  }
  private IsAssignDateVisble() {
    return this.localEventDetailViewModal.isEditMode && this.reqInfo.isApplicationLegacyModel && (this.oldLocalapplicationInstanceModel.StartDate === null && this.oldLocalapplicationInstanceModel.EndDate === null) ? true : false;
  }
  private IsUnspecifiedLocationOptionVisible() {
    return this.localEventDetailViewModal.isEditMode  && this.localEventDetailViewModal.applicationInstanceModel.LocationType === 0 ? true : false;
  }

  private isTabDisable(){
    Root.emit('disableTabs', false);
    if ((this.ValidationViewModel.Name === null || this.ValidationViewModel.Name === '') ||
        (this.localEventDetailViewModal.isGenerateCode &&
        (this.ValidationViewModel.ApplicationCode === null || this.ValidationViewModel.ApplicationCode === '')) || this.IsAdvertisementUrlEmpty()) {
      Root.emit('disableTabs', true);
    }
  }
  private IsAdvertisementUrlEmpty() {
    if(this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel !== null && this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel !== undefined) {
      return this.localEventDetailViewModal.applicationInstanceModel.AdvertisementViewModel.filter((item)=> ((item.BannerUrl === '' || item.BannerUrl === undefined) && !item.IsDeleted)).length > 0;
    }
  }
  private GetIconImageTitle() {
    return this.localEventDetailViewModal.applicationInstanceModel.ListLogoUrl === '' || this.localEventDetailViewModal.applicationInstanceModel.ListLogoUrl === '/content/images/ImageMissing.png' ? this.$t('EventSetting.AddIcon') : this.$t('EventSetting.EditIcon');
  }

  private changeSuperAdminHidddenEvent(){
    if(this.localEventDetailViewModal.applicationInstanceModel.Id === 0) {
      this.localEventDetailViewModal.applicationInstanceModel.IsSuperAdminHiddenEvent = !this.localEventDetailViewModal.applicationInstanceModel.IsSuperAdminHiddenEvent;
    }
  }
  private ToggleStripeTestMode(){
    if(this.localEventDetailViewModal.applicationInstanceModel.Id === 0) {
      this.localEventDetailViewModal.applicationInstanceModel.EnableStripeTestMode = !this.localEventDetailViewModal.applicationInstanceModel.EnableStripeTestMode;
    }
  }
  private IsTestStripeModeCheckVisible() {
    return this.reqInfo.IsRegistrationPFActive;
  }
}
export default toNative(EventDetail);
