export class AdvertisementViewModel {
  public static createEmpty(): AdvertisementViewModel {
    return new AdvertisementViewModel();
  }
  constructor(
    public Id: string = '',
    public PageUrl: string = '',
    public BannerUrl: string = '',
    public BannerUrlOriginal: string ='',
    public ClickCount: number = 0,
    public UrlIsInValid: boolean = false,
    public IsDeleted: boolean = false,
    public IsRequired: boolean = false,
  ) {}
}
