
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import Toaster from '@/components/Common/Toaster.vue';
import loading from '@/components/Common/loading.vue';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import { ChannelInfo } from '@/entities/Chat/ChannelInfo';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { FetchData, Root, t } from '@/main';
import ShowToaster from '@/entities/Emits/EventSettings/ShowToaster';
import ImageProcess from '@/components/Common/ImageProcess.vue';
import { useVuelidate } from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import Common from '@/helper/Common';
import ChatChannelType from '@/enums/ChatChannelType';
@Component({
  components: {
    Toaster,
    loading,
    VueModalPopUp,
    ImageProcess,
    ValidationSummary,
  },
})
class ChatDetail extends Vue {
  @Prop() chatData!: ChannelInfo;
  // Local chat data (initialized with empty data)
  private localChat: ChannelInfo = ChannelInfo.createEmpty();
  private oldLocalChat: ChannelInfo = ChannelInfo.createEmpty();
  private showImageProcess = false;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private isValidationSummaryVisible = false;
  private validationErrorFields: string[] = [];
  private validationSummaryHeading = t('Warning').toString();
  private isObjectDirty = false;
  private readonly EventModule = 'Event';

  private mounted() {
    this.localChat = structuredClone(this.chatData);
    this.state.localChat = this.localChat;
    this.oldLocalChat = structuredClone(this.localChat);
    Root.emit('disableTabs', true);
  }
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }

  private rules = {
    localChat: {
      Name: {
        required,
        minLength: minLength(1),
      },
    },
  };

  private state = reactive({
    localChat: ChannelInfo.createEmpty(),
  });

  private v$ = useVuelidate(this.rules, this.state);
  private Discard() {
    // dirty check false
    Root.emit('is-dirty',false);
    // clear dirty flag
    this.isObjectDirty = false;
    // clear validation
    this.validationErrorFields = [];
    this.isValidationSummaryVisible = false;
    // restore old data
    this.localChat = structuredClone(this.oldLocalChat);
    this.state.localChat = this.localChat;
  }
  // submit data
  private Submit(val: any) {
    // empty validation check
    this.validationErrorFields = [];
    this.isValidationSummaryVisible = false;
    //     validation check
    if (
      this.localChat.Name === null ||
      this.localChat.Name === ''
    ) {
      // set validation error
      this.validationErrorFields.push(
        this.$t('EventSetting.Input.Event').toString(),
      );
      this.isValidationSummaryVisible = true;
    }
    //     validation check end show
    if (this.isValidationSummaryVisible) {
      val.$touch();
      return;
    }
    // call save function
    this.SaveChatChannel();
  }

  private SaveChatChannel() {
    // show loader
    Root.emit('show-loader', true);
    // axios api hit to save the data of channel
    let saveOrUpdateAPI = '';
    let isCreateNew = false;
    if (this.localChat.Id === '') {
      this.localChat.GUID = Common.GenerateGuid();
      this.localChat.Type = ChatChannelType.PublicWithMembership.toString();
      this.localChat.Details.EntityId = this.localChat.EventId;
      this.localChat.Details.EntityType = this.EventModule;
      saveOrUpdateAPI = '/ApplicationInstance/AddChannel';
      isCreateNew = true;
    } else {
      saveOrUpdateAPI = '/ApplicationInstance/UpdateChannel';
    }
    FetchData(
      saveOrUpdateAPI,
      JSON.stringify({
        channelInfo: this.localChat,
      }),
    )
      .then((response: any) => {
        if (response.error) {
          /* empty */
        } else {
          // update old data
          this.oldLocalChat = structuredClone(this.localChat);
          // update new data state
          this.state.localChat = this.localChat;
          // set dirty flag
          this.isObjectDirty = false;
          this.$emit('is-dirty');
          Root.emit('is-dirty', false);
          // empty validation error fields
          this.validationErrorFields = [];
          this.isValidationSummaryVisible = false;
          // show toaster message on success
          const toasterData = ShowToaster.createEmpty();
          setTimeout(() => {
            // hide loader
            Root.emit('show-loader', false);
            // update message
            toasterData.Message = this.$t(
              'EventSetting.Chat.ChannelUpdatedSuccessfully',
            ).toString();
            if (isCreateNew) {
              toasterData.Message = this.$t(
                'EventSetting.Chat.ChannelCreatedSuccessfully',
              ).toString();
            }
            toasterData.Val = true;
            Root.emit('show-toaster', toasterData);
            setTimeout(() => {
              toasterData.Val = false;
              Root.emit('show-toaster', toasterData);
            }, 2000);
            this.$emit('BackNavigation', false);
          }, 1000);
          // navigate back
          // get updated data
          this.$emit('GetUpdatedChatChannelsData');
        }
      })
      .catch(() => {
        Root.emit('show-loader', true);
      });
  }

  // Back navigation
  private BackNavigation() {
    this.$emit('BackNavigation', false);
  }
  // private GetImageUrl() {
  //   return this.localChat.Details.ImageUrl
  //     ? this.missingImage
  //     : this.localChat.Details.ImageUrl;
  // }
  // private GetImageUploadUrl() {
  //   let url = this.missingImage;
  //   if (this.localChat != null) {
  //     url =
  //       this.localChat.Details.ImageUrl === null
  //         ? this.missingImage
  //         : this.localChat.Details.ImageUrl;
  //   }
  //   return url;
  // }
  // private SetImageIconUrl(iconUrl: string, iconUrlOriginal: string) {}
  // private clickOnImage(val: boolean) {
  //   this.showImageProcess = val;
  // }
  // private GetPreviousIconUrl(iconUrl: string, iconUrlOriginal: string) {
  //   if (this.localChat != null) {
  //     this.localChat.Details.ImageUrl = iconUrl;
  //   }
  // }

  // Set dirty flag and emit
  private IsDirtyFlag() {
    // set dirty flag
    this.isObjectDirty = true;
    this.$emit('is-dirty');
    Root.emit('is-dirty', true);
    // set tab disable
    this.isTabDisable();
  }
  // Set tab disable
  private isTabDisable() {
    Root.emit('disableTabs', true);
  }
  // check dirty state of local object
  private isDirtyObject() {
    const objectState =
      JSON.stringify(this.localChat) === JSON.stringify(this.oldLocalChat);
    // alert(objectState);
    if (!objectState) {
      this.IsDirtyFlag();
      this.isTabDisable();
    }
  }

  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.isValidationSummaryVisible = visible;
  }
}
export default toNative(ChatDetail);
