
import { Component, Vue, Prop, Watch, toNative } from 'vue-facing-decorator';
import { DialogueThemeEntity } from '@/entities/DialogueTheme/DialogueThemeEntity';
import { Chart } from 'highcharts-vue';
@Component({
  components: {
    highcharts: Chart,
  },
})
class DialogueHighcharts extends Vue {
  @Prop() chartData!: DialogueThemeEntity;
  @Prop() chartType!: string;
  private localChartType = '';
  private dataValue: any[] = [];
  private chartColors: any[] = [];
  private data: object = {};
  private options: object = this.data;
  private localChartData: DialogueThemeEntity = DialogueThemeEntity.createEmpty();
  private showCharts = false;
  private color = '';

  private mounted() {
    this.localChartType = this.chartType;
    if (this.chartData !== null && this.chartData !== undefined) {
      this.localChartData = Object.assign({}, this.chartData);
      this.dataValue = this.localChartData.Chartdata;
      this.GetColors();
      this.CreatechartData();
    }
  }

  private GetColors() {
    if (this.localChartData.Id !== null && this.localChartData.Id !== undefined) {
      this.showCharts = true;
      this.chartColors = [];
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < this.localChartData.ThemeColors.length; i++) {
        this.color = this.localChartData.ThemeColors[0].Color;
        this.chartColors.push(this.localChartData.ThemeColors[i].Color);
      }
    }
  }

  private beforeUpdate() {
    if (this.chartData !== null && this.chartData !== undefined) {
      this.localChartData = this.chartData;
      this.dataValue = this.localChartData.Chartdata;
      this.showCharts = true;
    }
    this.CreatechartData();
  }

  private CreatechartData() {
    this.data = {
      chart: {
        type: this.localChartType,
        backgroundColor: 'rgba(0,0,0,0)',
        margin: this.localChartType === 'column' ? [0, 0, 3, 0] : [0,0,0,0],
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        style: {
          display: 'none',
        },
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
        },
        series: {
          colorByPoint: true,
          borderWidth: 0,
          enableMouseTracking: false,
          groupPadding: 0.1,
        },
      },
      series: [
        {
          colors: (!this.localChartData.IsMultiColorTheme&&this.localChartType === 'column') ? [this.color]: this.chartColors,
          showInLegend: false,
          data: this.dataValue,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      ],
    };
    this.options = this.data;
  }

  @Watch('chartData.ThemeColors',{deep: true})
  private CheckForChangeInColor(newColor: any) {
    this.chartColors = [];
    for (let i = 0; i < this.localChartData.ThemeColors.length; i++) {
      this.color = newColor[0].Color;
      this.chartColors.push(newColor[i].Color);
    }
    this.data = {};
    this.options = this.data;
  }
}
export default toNative(DialogueHighcharts);

