
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { CardViewModel } from '@/entities/CardViewModel';
import ControlType from '@/enums/ControlType';
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { StoreHelper } from '@/store/StoreHelper';
import { Store } from '@/main';

@Component
class Card extends Vue {
  @Prop() private eventVisibilityCard?: CardViewModel;

  private localCard = new CardViewModel();
  private readMoreIcon = require('@/assets/Images/read-more-icon.svg');
  private copyIcon = require('@/assets/Images/copy-icon.svg');
  private isReadMoreVisible = false;
  private isHideTitle = false;
  private isReadMoreEnabled = false;
  private contactUsToEnableLink = 'https://support.ventla.io/in-admin-tutorials/g5lc4iik0pUZjRg2sQFv/contact-us-to-enable';
  private hollowGreenTickIcon = require('@/assets/Images/hollow-green-tick.svg');
  private exclamationIcon = require('@/assets/Images/exclamation-icon.svg');
  private informationIcon = require('@/assets/Images/info-icon.svg');
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
  private isCallbackReturned = false;

  private mounted() {
    // returns a boolean value if the user is using a mobile or ipad browser or not
    this.isReadMoreEnabled = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    this.localCard = this.eventVisibilityCard!;
    if (this.localCard !== null && this.localCard !== undefined && (this.localCard.Title === '' || this.localCard.Title === null)) {
      this.isHideTitle = true;
    }
    this.isCallbackReturned = this.reqInfo.IsZapierAvailableInApplicationZapierCallback;
  }
  private selectItem(val: CardViewModel) {
    if (this.localCard.ControlType === ControlType.GreenTick || this.localCard.ControlType === ControlType.CheckBox || this.localCard.ControlType === ControlType.CheckBoxAndLabel) {
      this.localCard.IsSelected = !this.localCard.IsSelected;
      this.$emit('selected-card-value', val);
    }
  }
  private getReadMore(val: boolean) {
    if (!this.isReadMoreEnabled) {
      this.isReadMoreVisible = val;
    }
  }
  private IsDesciptionHidden() {
    return this.localCard.ControlType === ControlType.CheckBoxAndLabel && this.localCard.IsSelected && this.IsInputBoxVisible();
  }
  private IsInputBoxVisible() {
    return (this.localCard.InputBoxData !== null && this.localCard.InputBoxData !== undefined && this.localCard.InputBoxData !== '');
  }
  private CopyKeyToClipboard(val: string) {
    this.$emit('show-copied-key',val);
  }
  private GetIconImage() {
    return this.IsInputBoxVisible() && this.isCallbackReturned ? this.hollowGreenTickIcon : (this.IsInputBoxVisible() ? this.informationIcon : this.exclamationIcon);
  }
}
export default toNative(Card);
